import axiosObj from 'api/config';

export const allUsers = () => {
  return axiosObj({
    method: 'GET',
    url: '/c/admin/user/',
  });
};

export const allUserInvites = () => {
  return axiosObj({
    method: 'GET',
    url: '/c/admin/user_invites/',
  });
};

export const changeUserRole = payload => {
  return axiosObj({
    method: 'POST',
    url: '/c/admin/user/',
    data: payload,
  });
};

export const resendInviteEmail = (id, payload = {}) => {
  return axiosObj({
    method: 'POST',
    url: `/c/admin/user_invites/${id}/resend_invite_email/`,
    data: payload,
  });
};

export const deleteUser = email => {
  return axiosObj({
    method: 'DELETE',
    url: `/c/admin/user/${email}/`,
  });
};

export const getRBACPolicy = params => {
  return axiosObj({
    method: 'GET',
    url: '/rbac/policy/',
    params,
  });
};

export const createRBACPolicy = ({ data }) => {
  return axiosObj({
    method: 'POST',
    url: `/rbac/policy/`,
    data,
  });
};

export const editRBACPolicy = ({ data, id }) => {
  return axiosObj({
    method: 'PATCH',
    url: `/rbac/policy/${id}/`,
    data,
  });
};

export const deleteRBACPolicy = id => {
  return axiosObj({
    method: 'DELETE',
    url: `/rbac/policy/${id}/`,
  });
};

export const getAllAccessibleShowbacks = () => {
  return axiosObj({
    method: 'GET',
    url: '/rbac/current_user_showback/get_all_accessible_showbacks/',
  });
};

export const getAllAccessibleShobackValues = params => {
  return axiosObj({
    method: 'GET',
    url: '/rbac/current_user_showback/get_all_accessible_showback_values/',
    params,
  });
};
