import cloneDeep from 'lodash/cloneDeep';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EmptySvg, SearchIcon } from 'assets/images';
import { NextArrowIcon } from 'assets/images/common';
import classNames from 'classnames';
import { Anchor } from 'components/Custom';
import { setSearchKey, setSearchValue, setSelectedKeyValue } from './reducer';
import {
  makeSelectTagResourcesLoading,
  makeSelectTagResourcesValue,
  makeSelectSearchKey,
  makeSelectSearchValue,
  makeSelectSelectedKeyValue,
  makeSelectSelectedTag,
  makeSelectSelectedTagCloudPlatform,
} from './selectors';

export const RenderKeyPopup = ({
  openValuePopUp,
  tagResources,
  configCatAzure,
  disableAWSFilters,
  disableAzureFilters,
}) => {
  const dispatch = useDispatch();
  const selectedKeyValue = useSelector(makeSelectSelectedKeyValue);
  const searchKey = useSelector(makeSelectSearchKey);
  const handleSearchKeyChange = e => dispatch(setSearchKey(e.target.value));

  const handleCheckKeyChange = (key, cloudPlatform) => () => {
    const selectedKeyValue_clone = [...selectedKeyValue];
    const recIndex = selectedKeyValue.findIndex(s => s.key === key);

    if (recIndex !== -1) {
      const { value, type } = selectedKeyValue_clone[recIndex];

      if (value.includes(`No Tagkey:${key}`) && type === 'Include') {
        selectedKeyValue_clone.splice(recIndex, 1);
      } else {
        selectedKeyValue_clone[recIndex] = {
          key,
          cloudPlatform,
          value: [`No Tagkey:${key}`],
          type: 'Include',
        };
      }
    } else {
      selectedKeyValue_clone.push({
        key,
        cloudPlatform,
        type: 'Include',
        value: [`No Tagkey:${key}`],
      });
    }

    dispatch(setSelectedKeyValue(selectedKeyValue_clone));
  };

  let data = cloneDeep(tagResources);

  if (searchKey) {
    data = data.filter(x => (x?.key || '').toLowerCase().includes((searchKey || '').toLowerCase()));
  }

  if (configCatAzure) {
    if (disableAWSFilters) {
      data = data.filter(x => !(x?.cloudPlatform || 'aws').toLowerCase().includes('aws'));
    }

    if (disableAzureFilters) {
      data = data.filter(x => !(x?.cloudPlatform || 'aws').toLowerCase().includes('azure'));
    }
  }

  return (
    <div className="tagBody">
      <div className="np-input-icon">
        <input
          id="searchTag"
          type="text"
          placeholder="Search for tag.."
          className="np-input search-input-filter"
          value={searchKey}
          onChange={handleSearchKeyChange}
        />
        <SearchIcon />
      </div>

      <ul className="tagsList">
        {data.map((tag, index) => {
          const rec = selectedKeyValue.find(selected => selected.key === tag.key);
          const con = rec ? rec?.value?.[0] === `No Tagkey:${tag.key}` && rec?.type === 'Include' : false;

          return (
            <li
              key={index}
              className={classNames({
                'np-filter-azure': configCatAzure && tag.cloudPlatform === 'azure',
                'np-filter-aws': configCatAzure && tag.cloudPlatform === 'aws',
              })}
            >
              <div className="np-check">
                <input
                  id={'key' + tag.key + index}
                  type="checkbox"
                  checked={con}
                  onChange={handleCheckKeyChange(tag.key, tag.cloudPlatform)}
                />
                <label className="np-check-label" htmlFor={'key' + tag.key + index}>
                  <EmptySvg />
                </label>
              </div>
              <Anchor
                id={'openPopup-' + tag.key}
                onClick={!con ? openValuePopUp(tag.key, tag.cloudPlatform) : () => {}}
              >
                {tag.key}
                {!con ? (
                  <i className="np-svgIcon">
                    <NextArrowIcon />
                  </i>
                ) : null}
              </Anchor>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export const RenderValuePopup = ({ configCatAzure }) => {
  const dispatch = useDispatch();
  const selectedKeyValue = useSelector(makeSelectSelectedKeyValue);
  const searchValue = useSelector(makeSelectSearchValue);
  const tagResourcesValue = useSelector(makeSelectTagResourcesValue);
  const tagResourcesLoading = useSelector(makeSelectTagResourcesLoading);
  const selectedTag = useSelector(makeSelectSelectedTag);
  const selectedTagCloudPlatform = useSelector(makeSelectSelectedTagCloudPlatform);

  if (tagResourcesLoading) {
    return (
      <li>
        <div className="np-filterList">
          <label className="np-filterLabel">Loading...</label>
        </div>
      </li>
    );
  }

  const valueList = tagResourcesValue.find(
    tag =>
      tag.key === selectedTag &&
      (!configCatAzure || (configCatAzure && tag.cloudPlatform === selectedTagCloudPlatform)),
  );

  const keyIndex = selectedKeyValue.findIndex(
    tag =>
      tag.key === selectedTag &&
      (!configCatAzure || (configCatAzure && tag.cloudPlatform === selectedTagCloudPlatform)),
  );
  const details = selectedKeyValue[keyIndex];
  const handleSearchValueChange = e => dispatch(setSearchValue(e.target.value));

  const handleCheckValueChange = key => () => {
    const selectedKeyValue_clone = [...selectedKeyValue];
    const recIndex = details?.value.findIndex(value => value === key);

    if (recIndex !== -1) {
      const values_clone = [...details.value];
      values_clone.splice(recIndex, 1);
      selectedKeyValue_clone[keyIndex] = { ...details, value: values_clone };
    } else if (key === `No Tagkey:${selectedTag}`) {
      selectedKeyValue_clone[keyIndex] = {
        ...details,
        value: [key],
        type: 'Exclude',
      };
    } else {
      selectedKeyValue_clone[keyIndex] = {
        ...details,
        value: [...details.value, key],
      };
    }

    dispatch(setSelectedKeyValue(selectedKeyValue_clone));
  };

  const defaultRec = { key: `No Tagkey:${selectedTag}` };
  let data = cloneDeep(valueList?.data || []);
  data = [defaultRec, ...data];

  if (searchValue) {
    data = data.filter(value => (value?.key || '').toLowerCase().includes((searchValue || '').toLowerCase()));
  }

  return (
    <div className="tagBody">
      <div className="np-input-icon">
        <input
          id="searchValue"
          type="text"
          placeholder="Search for key value.."
          className="np-input search-input-filter"
          value={searchValue}
          onChange={handleSearchValueChange}
        />
        <SearchIcon />
      </div>

      <ul>
        {!data.length ? (
          <li>
            <div className="np-filterList">
              <label className="np-filterLabel">No tag values found for {selectedTag} tag.</label>
            </div>
          </li>
        ) : (
          data.map((tag, index) => {
            const con = details.value.includes(`No Tagkey:${selectedTag}`) && tag.key !== `No Tagkey:${selectedTag}`;

            return (
              <li key={index} className={con ? 'disabled' : ''}>
                <div className="np-filterList">
                  <div
                    className={classNames({
                      'np-filter': true,
                      'np-filter-azure': configCatAzure && tag.cloudPlatform === 'azure',
                      'np-filter-aws': configCatAzure && tag.cloudPlatform === 'aws',
                    })}
                  >
                    <div className="np-check">
                      <input
                        id={tag.key + index}
                        type="checkbox"
                        checked={details.value.includes(tag.key)}
                        onChange={!con ? handleCheckValueChange(tag.key) : () => {}}
                      />
                      <label className="np-check-label" htmlFor={tag.key + index}>
                        <EmptySvg />
                      </label>
                    </div>
                  </div>

                  <label className="np-filterLabel" htmlFor={tag.key + index}>
                    {tag.key}
                  </label>
                </div>
              </li>
            );
          })
        )}
      </ul>
    </div>
  );
};
