import { createSelector } from 'reselect';

export const selectFilter = state => state.global.filter;
export const selectGlobal = state => state.global;
export const selectFeatureFlags = state => state.global.featureFlags;

export const makeGlobalLoading = () => createSelector(selectGlobal, state => state?.loading || false);

export const makeSelectUserLoading = () => createSelector(selectGlobal, state => state?.userLoading || false);

export const makeSelectClientLoading = () => createSelector(selectGlobal, state => state?.clientLoading || false);

export const makeSelectProjectsLoading = () => createSelector(selectGlobal, state => state?.projectsLoading || false);

export const makeAccountFilter = () => createSelector(selectFilter, state => state?.account || []);

export const makeSelectHasProject = () => createSelector(selectGlobal, state => state?.hasProject || false);

export const makePaymentSelector = () => createSelector(selectGlobal, state => state?.userData?.payment || null);

export const makeAutoSuggestion = () => createSelector(selectGlobal, state => state?.autoSuggestionData || []);

export const makeAutoSuggestionLoading = () => createSelector(selectGlobal, state => state?.suggestionLoader || false);

export const makeUserData = () => createSelector(selectGlobal, state => state?.userData || {});

export const makeProjectStatus = () => createSelector(selectGlobal, state => state?.projectStatus || {});

export const makeSelectClientId = () => createSelector(selectGlobal, state => state?.userData?.client || null);

export const makeUserProfile = () => createSelector(selectGlobal, state => state?.userData?.profile || null);

export const makeUserProjects = () => createSelector(selectGlobal, state => state?.userData?.projects || null);

export const makeAzureBillingCurrency = () =>
  createSelector(selectGlobal, state => state?.userData?.azure_billing_currency || null);

export const makeIsMultipleCurrency = () =>
  createSelector(selectGlobal, state => state?.userData?.isMultipleCurrency || false);

export const makeShowToast = () => createSelector(selectGlobal, state => state?.showToast || {});

export const makeSelectOverrideTimeStamp = () => createSelector(selectGlobal, state => state?.overrideTimeStamp || '');

export const makeSelectTheme = () => createSelector(selectGlobal, state => state?.theme || 'light');

export const makeSelectPolicyData = () => createSelector(selectGlobal, state => state?.policyData || {});

export const makeSelectPolicyLoading = () => createSelector(selectGlobal, state => state?.policyLoading || false);

export const makeJiraCredStatusLoading = () => createSelector(selectGlobal, state => state?.credStatusLoading || false);

export const makeJiraCredStatus = () => createSelector(selectGlobal, state => state?.credStatus || 0);

export const makeJiraIssuesLoading = () => createSelector(selectGlobal, state => state?.jiraIssuesLoading || false);

export const makeJiraIssuesData = () => createSelector(selectGlobal, state => state?.jiraIssues || []);

export const makeConfigCatAzure = () => createSelector(selectGlobal, state => state?.configCatAzure);

export const makeSelectJiraOAuth2Data = () => createSelector(selectGlobal, state => state?.jiraOAuth2Data || []);

export const makeSelectJiraV2Loading = () => createSelector(selectGlobal, state => state?.jiraV2Loading || false);

export const isFeatureEnabled = flagNames =>
  createSelector(selectFeatureFlags, featureFlags =>
    flagNames.map(flagName => featureFlags?.data?.[flagName] || false),
  );
