import React from 'react';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import Autosuggest from 'react-autosuggest';
import axiosObj from 'api/config';
import { getDSLAutocompleteData } from './dslQuery';
import { CloseIcon } from 'assets/images/modal';
import { Anchor } from 'components/Custom';

export default class QuerySearchSuggestion extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      Loading: false,
      suggestionData: [],
      search: props?.name || '',
    };

    this.debounceSearch = debounce(newName => {
      const dslData = getDSLAutocompleteData();

      if (!dslData || isEmpty(dslData)) {
        this.setState({ loading: false });

        return null;
      }

      this.getAdvanceSearchData(dslData);
      this.checkIsValidQuery(newName);
    }, 200);
  }

  componentDidMount() {
    this.debounceSearch('');
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props?.name !== prevProps?.name) {
      this.setState({
        search: this.props.name,
      });
    }

    if (this.state.search !== prevState.search) {
      this.debounceSearch(this.state.search);
    }
  }

  checkIsValidQuery = newName => {
    if (!newName) {
      return null;
    }

    axiosObj({
      url: `/c/ac/dsl-validation?query=${newName}`,
      method: 'GET',
      params: {
        scope: this.props.isPartner ? 'partner' : 'client',
      },
    })
      .then(results => {
        if (!results?.data?.valid) {
          this.setState({ error: true });
          this.updateParentError('Query is not valid');
        } else {
          this.setState({ error: false });
          this.updateParentError('');
        }
      })
      .catch(() => {
        this.setState({ error: true });
        this.updateParentError('Query is not valid');
      });
  };

  updateParentError = error => {
    typeof this.props.updateError === 'function' && this.props.updateError(error);
  };

  getAdvanceSearchData = (
    dslData = {
      posFrom: -1,
      posTo: 0,
      value: '',
      type: 'field',
      relatedField: '',
    },
  ) => {
    const defaultData = {
      type: dslData.type,
      posFrom: dslData.posFrom,
      posTo: dslData.posTo,
    };
    const url = `/c/ac/dsl-autocomplete?query=${dslData.value}&type=${dslData.type}&field_name=${dslData.relatedField}`;
    axiosObj({ url, method: 'GET' })
      .then(results => {
        let filteredResults;

        if (dslData.type === 'field') {
          filteredResults = (results?.data?.data || []).map(a => ({
            name: a.value,
            ...defaultData,
          }));
        } else {
          filteredResults = (results?.data?.data || []).map(a => ({
            name: a,
            ...defaultData,
          }));
        }

        this.setState({
          loading: false,
          suggestionData: filteredResults,
        });
      })
      .catch(e => {
        this.setState({ loading: false });
      });
  };

  getSuggestionData = () => {
    const { suggestionData, loading, search, error } = this.state;
    const data = (suggestionData || []).filter(s => s.name);

    if (search && loading) {
      return [{ name: 'Loading...' }];
    } else if (search && !error && !loading && !data?.length && this.props.isSearch) {
      return [
        {
          name: 'Hit < Enter > to search',
        },
      ];
    } else {
      return data;
    }
  };

  getSuggestionValue = suggestion => suggestion.name;

  renderSuggestion = suggestion => <div>{suggestion.name}</div>;

  onChange = (event, { newValue, method }) => {
    if (newValue === 'Hit < Enter > to search' || newValue === 'Loading...') {
      this.setState({ search: '' });
    } else if (method === 'type') {
      this.setState({ search: newValue });
    } else if (method === 'enter') {
      event.preventDefault();
      event.stopPropagation();
      this.setState({ search: newValue });
    }
  };

  onClick = e => {
    if (e.key === 'Enter' && !this.state.error) {
      typeof this.props.onClick === 'function' && this.props.onClick(this.state.search, !this.state.error);
    }
  };

  onBlur = e => {
    if (!this.props?.isSearch) {
      typeof this.props.onClick === 'function' && this.props.onClick(this.state.search, this.state.error);
    }
  };

  onSuggestionSelected = (event, { suggestion }) => {
    if (suggestion.name === 'Hit < Enter > to search' || suggestion.name === 'Loading...') {
      this.setState({ search: '' });
    } else {
      let dslString = document.getElementById('dsl-input').value;
      let suggestionName = suggestion.name;

      if (suggestion.type === 'value' && !new RegExp('^[a-zA-Z_][a-zA-Z0-9_]*$').test(suggestion.name)) {
        suggestionName = `"${suggestionName}"`;
      }

      dslString =
        dslString.slice(0, suggestion.posFrom - 1) +
        suggestionName +
        dslString.slice(suggestion.posTo, dslString.length);
      this.setState({ search: dslString });
    }
  };

  clearData = () => {
    this.setState(
      {
        search: '',
        error: '',
      },
      () => this.props.clearFiltration(),
    );
  };

  render() {
    const { search, error } = this.state;
    const { showButtons } = this.props;

    const inputProps = {
      id: 'dsl-input',
      placeholder: 'Write a query..',
      value: search,
      className: 'np-input',
      onChange: this.onChange,
      onKeyDown: this.onClick,
      onBlur: this.onBlur,
    };

    return (
      <div className="np-input-group">
        <div className="queryStatusWrap">
          {!error ? (
            <span className="queryStatus green">
              <svg aria-hidden="true" width="15" height="15" version="1.1" viewBox="0 0 26 26">
                <path d="m.3,14c-0.2-0.2-0.3-0.5-0.3-0.7s0.1-0.5 0.3-0.7l1.4-1.4c0.4-0.4 1-0.4 1.4,0l.1,.1 5.5,5.9c0.2,0.2 0.5,0.2 0.7,0l13.4-13.9h0.1v-8.88178e-16c0.4-0.4 1-0.4 1.4,0l1.4,1.4c0.4,0.4 0.4,1 0,1.4l0,0-16,16.6c-0.2,0.2-0.4,0.3-0.7,0.3-0.3,0-0.5-0.1-0.7-0.3l-7.8-8.4-.2-.3z"></path>
              </svg>
            </span>
          ) : (
            <span className="queryStatus red">
              <CloseIcon />
            </span>
          )}
        </div>
        <Autosuggest
          id={'AutoSuggest'}
          suggestions={this.getSuggestionData()}
          onSuggestionsFetchRequested={() => {}}
          onSuggestionsClearRequested={() => {}}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          inputProps={inputProps}
          onSuggestionSelected={this.onSuggestionSelected}
          shouldRenderSuggestions={() => {
            return true;
          }}
        />
        {showButtons ? (
          <Anchor
            id={'save-as'}
            className={`np-button blue ${!search || error ? 'disabled' : ''}`}
            onClick={search && !error ? this.props.handleSaveAs(search) : () => {}}
          >
            Save as
          </Anchor>
        ) : null}
        <div className="query-options">
          <Anchor className="clear-btn" onClick={this.clearData}>
            Clear
          </Anchor>
        </div>
      </div>
    );
  }
}
