import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import moment from 'moment';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';

import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import TabComponent from 'components/Common/TabComponent';
import ErrorBoundary from 'components/Common/ErrorBoundary';
import FeatureFlagsContext from 'context/feature-flags-context';
import { CMRuleIcon } from 'assets/images/nOpsRule';
import {
  AWSAccountIcon,
  AWSRegionIcon,
  AWSProductIcon,
  AWSResourceIcon,
  AWSNonResourceIcon,
  AWSTagIcon,
  AWSCompareIcon,
} from 'assets/images/cost';
import Filters from 'containers/Filters';
import globalReducer from 'containers/App/reducer';
import {
  makeAccountFilter,
  makeAzureBillingCurrency,
  makeIsMultipleCurrency,
  makeSelectHasProject,
} from 'containers/App/selectors';
import { COST_TYPE, COST_AGGS, COST_AGGS_AZURE, ROUTES_PATH } from 'utils/constants';
import { getParamFromQueryString, getNewQueryString, removeQueryString } from 'utils/queryString';
import {
  LAST_30_DAYS,
  YESTERDAY,
  FILTER_DATE_FORMAT,
  getFilterQuery,
  getFilterParams,
  changeDateToStringFormat,
} from 'utils/filter';
import { IsJsonValid, IsURIValid, returnTypeForAPI, setDefaultFilterData, checkIsEqual } from 'utils/helper';
import {
  makeSelectDataLoading,
  makeSelectFilterLoading,
  makeSelectCostFilters,
  makeSelectCostFiltersCloud,
  makeSelectCostForAll,
  summaryLoadingSelector,
  summaryDataSelector,
  summaryCreditsDataSelector,
  summaryFilterDataSelector,
  makeMRCSelectCostForAll,
  makeSelectCostDefaultFilters,
  makeSelectCostDefaultFiltersCloud,
  makeSelectCostDefaultFiltersLoading,
  makeSelectSetFilters,
} from '../selectors';
import {
  loadFilterDataRequest,
  loadAllCostDataRequest,
  loadCostSummaryRequest,
  loadMRCCostData,
  loadDefaultFilterDataRequest,
} from '../actions';
import reducer from '../reducer';
import saga from '../saga';
import TableChart from './TableChart';
import CreateChargeback from 'components/CreateChargeback';
import ChangeManagement from '../ChangeManagement';
import SpendSummary from './SpendSummary';
import { getTabData, getTabFilterData } from './helper';
import NoData, { NO_DATA_CONTENT } from 'components/NoData';
import { getDateRangeField } from './getDateRangeField';
import { showToastAction } from 'containers/App/actions';
import SelectedFilters from 'components/SelectedFilters';
import LoadingWrapper from 'components/ContentLoader/LoadingWrapper';
import { CostControlLoader, TagsLoader } from 'components/ContentLoader/CostControlLoaders';
import { DetailsCMPageLoader } from 'components/ContentLoader/RulesLoaders';
import CloudAccountBanner from 'containers/BasePage/cloudAccBanner';
import BillingInconsistencyBanner from 'containers/BasePage/inconsistencyBanner';

// Lazyload TagsContent page
const AWSTags = React.lazy(() => import('../AWSTagsNew'));
// constants
const key = 'COST';

const tabList = [
  { name: COST_TYPE.AWS_ACCOUNTS, icon: <AWSAccountIcon /> },
  { name: COST_TYPE.REGIONS, icon: <AWSRegionIcon /> },
  { name: COST_TYPE.AWS_PRODUCTS, icon: <AWSProductIcon /> },
  { name: COST_TYPE.RESOURCES, icon: <AWSResourceIcon /> },
  { name: COST_TYPE.NON_RESOURCES, icon: <AWSNonResourceIcon /> },
  { name: COST_TYPE.USAGE_TYPES, icon: <AWSTagIcon /> },
  { name: COST_TYPE.OPERATIONS, icon: <AWSCompareIcon /> },
];

export const filterAggs = ['arp_summary'];
export const filterAggsAzure = ['arp_summary_billing_type'];
const basePayload = {
  q: '*',
  scope: 'client',
  aggregations: '',
  show_active: null,
};
const defaultTab = tabList[0].name;
export const IS_SHOW = [
  'searchString',
  'startEndPicker',
  'account',
  'cloudformation',
  'resource',
  'region',
  'product',
  'include_credit',
  'launchStartEnd',
  'op',
  'ut',
];
export const filterProps = {
  account: {
    paramKey: 'arp_summary_projects',
    isCost: true,
  },
  region: {
    paramKey: 'arp_summary_regions',
    isCost: true,
  },
  product: {
    paramKey: 'arp_summary_products',
    isCost: true,
  },
};

export const convertStringDateToMoment = (date1, date2) => {
  if (typeof date1 !== 'string') {
    return date1;
  }

  return date1 ? moment(date1, FILTER_DATE_FORMAT) : cloneDeep(date2);
};

class AWSResourceCost extends PureComponent {
  static contextType = FeatureFlagsContext;

  constructor(props) {
    super(props);

    const selectedTab = getParamFromQueryString(props.location.search, 'tab') || defaultTab;

    this.SELECTED_AGGS = props.configCatAzure ? COST_AGGS_AZURE : COST_AGGS;

    const selectedAggs = get(this.SELECTED_AGGS, selectedTab);

    this.state = {
      tabList,
      selectedTab,
      selectedAggs,
      sorting: {
        type: 'desc',
        range: {
          from: LAST_30_DAYS.format(FILTER_DATE_FORMAT),
          to: YESTERDAY.format(FILTER_DATE_FORMAT),
        },
      },
      showCreateChargeback: false,
      isShowForecast: false,
      estimatedSpend: 0,
      showKeyword: false,

      searchString: '*',
      startDate: LAST_30_DAYS,
      endDate: YESTERDAY,
      launchStart: undefined,
      launchEnd: undefined,
      tagsAgg: props.configCatAzure ? 'arp_billing_type_tags_kv' : 'arp_tags_kv',
      cmKey: '',
      include_credit: false,
      tagData: {},
      cit: [],
      currentFilter: {},
      payload: {},
      isAWSSelected: true,
    };
  }

  componentDidMount = () => {
    this.mounted = true;

    const { location, hasProject, isMultipleCurrency = false } = this.props;
    this.renderTagsTab();

    if (hasProject) {
      const pData = getParamFromQueryString(location.search, 'data');

      if (!isEmpty(pData)) {
        this.setInitialFiltersData();
      } else if (isMultipleCurrency) {
        this.setState(
          {
            currentFilter: {
              'cloud_type.keyword': ['aws'],
            },
            payload: {
              cloudPlatformState: ['aws'],
              cloudPlatformState_values: [{ id: 'aws', value: 'AWS', cloudPlatform: 'aws' }],
            },
          },
          () => {
            this.compareAndCall();
          },
        );
      } else {
        this.compareAndCall();
      }
    }
  };

  componentDidUpdate = prevProps => {
    const { hasProject = false, location, configCatAzure } = this.props;
    const { location: prevLocation } = prevProps;

    if (hasProject && hasProject !== prevProps.hasProject) {
      this.setInitialFiltersData();
    }

    if (checkIsEqual(false, location.search, prevLocation.search)) {
      const selectedTab = getParamFromQueryString(location.search, 'tab') || defaultTab;

      this.SELECTED_AGGS = configCatAzure ? COST_AGGS_AZURE : COST_AGGS;

      const selectedAggs = get(this.SELECTED_AGGS, selectedTab);
      this.setState(
        {
          selectedTab,
          selectedAggs,
          isShowForecast: false,
        },
        () => {
          if (hasProject) {
            this.setInitialFiltersData();
          }
        },
      );
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  setInitialFiltersData = () => {
    const { currentFilter = {}, selectedTab, startDate, endDate, launchEnd, launchStart, tagData, cit } = this.state;
    let { searchString, include_credit, sorting, showKeyword } = this.state;
    const { history: { location = {} } = {} } = this.props;
    let filter = cloneDeep(currentFilter) || {};
    let tagD = cloneDeep(tagData);
    let citD = cloneDeep(cit);
    let newPayload = cloneDeep(this.state.payload);
    let startD = cloneDeep(startDate);
    let endD = cloneDeep(endDate);
    let launchSD = cloneDeep(launchStart);
    let launchED = cloneDeep(launchEnd);
    let data = getParamFromQueryString(location.search, 'data') || '';
    let isAWSSelected = this.state.isAWSSelected;

    data = IsJsonValid(IsURIValid(data));

    if (!isEmpty(data)) {
      const { payload = {} } = data;
      newPayload = payload;
      searchString = getFilterQuery(payload);
      startD = convertStringDateToMoment(payload.startDate, startD);
      endD = convertStringDateToMoment(payload.endDate, endD);
      newPayload.startDate = startD;
      newPayload.endDate = endD;

      if (!payload?.launchStart) {
        launchSD = undefined;
      } else {
        launchSD = convertStringDateToMoment(payload.launchStart, launchSD);
      }

      if (!payload?.launchEnd) {
        launchED = undefined;
      } else {
        launchED = convertStringDateToMoment(payload.launchEnd, launchED);
      }

      const filters = getFilterParams(payload);
      filter = { ...filters };
      tagD = payload?.tagData || tagData;
      citD = payload?.cit || cit;
      showKeyword = !!payload?.showKeyword;
      include_credit = !!payload?.include_credit;
      sorting = {
        type: 'desc',
        range: {
          from: changeDateToStringFormat(startD),
          to: changeDateToStringFormat(endD),
        },
      };
      isAWSSelected = (payload?.cloudPlatformState || []).includes('aws');
    }

    this.setState(
      {
        searchString,
        startDate: convertStringDateToMoment(startD),
        endDate: convertStringDateToMoment(endD),
        launchEnd: launchED,
        launchStart: launchSD,
        selectedTab,
        selectedAggs: get(this.SELECTED_AGGS, selectedTab),
        currentFilter: { ...filter },
        include_credit,
        tagData: tagD,
        cit: citD,
        showKeyword,
        sorting,
        isAWSSelected,
        payload: newPayload,
      },
      () => {
        this.compareAndCall();
      },
    );
  };

  compareAndCall = () => {
    const { costForAll, summaryFilter, defaultFiltersCloud, mrcData, summaryData, filtersCloud, selectedFilters } =
      this.props;
    const { selectedTab, payload } = this.state;
    const filterData = getTabFilterData(costForAll, selectedTab);
    const stateData = cloneDeep(payload);
    const flag = filterData?.data && (isEmpty(filterData.data) || !isEqual(filterData.filter, stateData));
    const flagForFilter = !isEqual(selectedFilters, stateData);
    const summaryFlag = isEmpty(summaryData) || !isEqual(summaryFilter, stateData);

    if (flag && ![COST_TYPE.BUDGETS].includes(selectedTab)) {
      this.loadCostData();
    }

    if (isEmpty(filtersCloud) || (flagForFilter && ![COST_TYPE.BUDGETS].includes(selectedTab))) {
      this.loadFilterData();
    }

    if (summaryFlag && ![COST_TYPE.BUDGETS, COST_TYPE.CM].includes(selectedTab)) {
      this.loadSummaryData();
    }

    if (isEmpty(defaultFiltersCloud)) {
      this.loadDefaultFilterData();
    }

    if (isEmpty(mrcData)) {
      this.props.loadMRCCostData();
    }
  };

  loadDefaultFilterData() {
    if (this.props.hasProject) {
      const parameters = {
        ...basePayload,
        // aggregations: filterAggs.join(';'),
        aggregations: this.props.configCatAzure ? filterAggsAzure.join(';') : filterAggs.join(';'),
        filters: {},
      };

      this.props.configCatAzure && (parameters.cloud_type = 'all');

      this.props.loadDefaultFilterDataRequest({
        parameters,
      });
    }
  }

  loadFilterData(customParams) {
    const {
      searchString,
      startDate = LAST_30_DAYS,
      endDate = YESTERDAY,
      currentFilter,
      launchStart,
      launchEnd,
      selectedTab,
      cit,
      tagData,
    } = this.state;
    let moreParams = {};

    if (selectedTab === COST_TYPE.BUDGETS) {
      return;
    }

    const startD = cloneDeep(startDate);
    const endD = cloneDeep(endDate);
    const launchSD = cloneDeep(launchStart);
    const launchED = cloneDeep(launchEnd);

    if (launchStart || launchEnd) {
      moreParams.range = {
        'cost.dates_available': {
          gte: launchSD && launchSD.format(FILTER_DATE_FORMAT),
          lte: launchED && launchED.format(FILTER_DATE_FORMAT),
          relation: 'within',
        },
      };
    }

    const filterInclude = setDefaultFilterData({
      filters: currentFilter,
      tagData,
      cit: cit,
    }).filters;

    moreParams = this.isCreditIngested(moreParams);

    const parameters = {
      ...basePayload,
      ...moreParams,
      q: searchString,
      cost_from: startD.format(FILTER_DATE_FORMAT),
      cost_to: endD.format(FILTER_DATE_FORMAT),
      // aggregations: filterAggs.join(';'),
      aggregations: this.props.configCatAzure ? filterAggsAzure.join(';') : filterAggs.join(';'),
      filters: filterInclude,
      ...customParams,
    };

    /* cloud_type should be 'all' - it's not, currentFilter is empty
    this.props.configCatAzure &&
      currentFilter['cloud_type.keyword'] &&
      currentFilter['cloud_type.keyword'].length > 0 &&
      (parameters.cloud_type = 'all');
    */

    //for non resources tab we need to send the property non_resources as true
    if (selectedTab === COST_TYPE.NON_RESOURCES) {
      parameters.non_resources = true;
    }

    this.props.configCatAzure && (parameters.cloud_type = 'all');
    this.props.hasProject &&
      this.props.loadFilterDataRequest({
        parameters,
      });
  }

  loadSummaryData = () => {
    const {
      startDate = LAST_30_DAYS,
      endDate = YESTERDAY,
      currentFilter,
      tagData,
      cit,
      payload,
      searchString,
      selectedTab,
    } = this.state;
    const { configCatAzure } = this.props;
    const type = getDateRangeField({ startDate, endDate });
    const agg = '';

    const filterInclude = setDefaultFilterData({
      filters: currentFilter,
      tagData,
      cit: cit,
    });
    const startD = cloneDeep(startDate);
    const endD = cloneDeep(endDate);
    let summaryParameters = {
      ...basePayload,
      q: searchString,
      aggregations: agg,
      cost_from: startD.format(FILTER_DATE_FORMAT),
      cost_to: endD.format(FILTER_DATE_FORMAT),
      filters: filterInclude.filters,
    };

    configCatAzure && (summaryParameters.cloud_type = 'all');

    // const isAzure = configCatAzure ? '_billing_type' : '';

    // for tabs operation and usagetype the aggregation is different
    let aggregationException = '';

    if (selectedTab === 'Operations') {
      aggregationException = '_operation';
    } else if (selectedTab === 'Usage Types') {
      aggregationException = '_usagetype';
    }

    switch (type) {
      case 'mtd':
        summaryParameters.aggregations = `arp${aggregationException}_spend_summary_billing_type`;
        delete summaryParameters.cost_to;
        delete summaryParameters.cost_from;

        break;
      case '1mo':
        summaryParameters.aggregations = `arp${aggregationException}_spend_summary_history_1mo_billing_type`;

        break;
      case '3mo':
        summaryParameters.aggregations = `arp_spend_summary_history_3mo`;

        break;
      case '6mo':
        summaryParameters.aggregations = `arp_spend_summary_history_6mo`;

        break;
      case 'weeks':
      default:
        summaryParameters.aggregations = `arp${aggregationException}_spend_summary_history_billing_type`;

        break;
    }

    let mustGetCredits = false;

    if ([COST_TYPE.CLOUD_ACCOUNTS, COST_TYPE.REGIONS, COST_TYPE.NON_RESOURCES].includes(this.state.selectedTab)) {
      mustGetCredits = true;
    }

    // Only on the Resource tab a new flag must be sent, include_only_resources: "true"
    if (selectedTab === COST_TYPE.RESOURCES) {
      summaryParameters.include_only_resources = 'true';
    }

    summaryParameters = this.isCreditIngested(summaryParameters);
    this.props.loadCostSummaryRequest({
      parameters: summaryParameters,
      state: payload,
      type: returnTypeForAPI(filterInclude.filters, filterInclude.workload),
      creditsParams: {
        mustGetCredits,
        cost_from: startD.format(FILTER_DATE_FORMAT),
        cost_to: endD.format(FILTER_DATE_FORMAT),
      },
    });
  };

  showForecastChart = active => () => {
    this.setState({
      isShowForecast: Boolean(active),
    });
  };

  setEstimatesSpend = data => {
    this.setState({ estimatedSpend: data });
  };

  isCreditIngested = parameters => {
    const { include_credit, selectedTab } = this.state;

    if (selectedTab !== COST_TYPE.OPERATIONS && selectedTab !== COST_TYPE.USAGE_TYPES) {
      const param = cloneDeep(parameters);
      param.include_credit = include_credit ? 'none' : 'all';

      return param;
    } else {
      return parameters;
    }
  };

  loadCostData(customParams) {
    const {
      searchString,
      startDate = LAST_30_DAYS,
      endDate = YESTERDAY,
      currentFilter,
      selectedAggs,
      launchStart,
      launchEnd,
      tagData,
      cit,
      sorting,
      payload,
      selectedTab,
    } = this.state;
    let moreParams = {};
    const startD = cloneDeep(startDate);
    const endD = cloneDeep(endDate);
    const launchSD = cloneDeep(launchStart);
    const launchED = cloneDeep(launchEnd);
    const { configCatAzure } = this.props;

    if (launchStart || launchEnd) {
      moreParams = {
        range: {
          'cost.dates_available': {
            gte: launchSD && launchSD.format(FILTER_DATE_FORMAT),
            lte: launchED && launchED.format(FILTER_DATE_FORMAT),
            relation: 'within',
          },
        },
      };
    }

    if (isEmpty(selectedAggs)) {
      return;
    }

    const filterInclude = setDefaultFilterData({
      filters: currentFilter,
      tagData,
      cit: cit,
    }).filters;
    let parameters = {
      ...basePayload,
      ...moreParams,
      aggregations: [selectedAggs].join(';'),
      q: searchString,
      cost_from: startD.format(FILTER_DATE_FORMAT),
      cost_to: endD.format(FILTER_DATE_FORMAT),
      filters: filterInclude,
      cost_forecast: true,
      cost_sort: sorting,
      ...customParams,
    };

    if (configCatAzure) {
      parameters.cloud_type = 'all';
    }

    // Only on Resources tab a new flag must be sent, include_only_resources = 'true'
    if (selectedTab === COST_TYPE.RESOURCES) {
      parameters.include_only_resources = 'true';
    }

    parameters = this.isCreditIngested(parameters);
    this.props.hasProject &&
      this.props.loadAllCostDataRequest({
        parameters: parameters,
        tab: this.state.selectedTab,
        'cost.dates_available': {
          gte: launchSD && launchSD.format(FILTER_DATE_FORMAT),
          lte: launchED && launchED.format(FILTER_DATE_FORMAT),
          relation: 'within',
        },
        state: payload,
      });
  }

  selectTab = name => {
    const search = removeQueryString(this.props.location.search, 'cmTab');
    this.props.history.push({
      pathname: ROUTES_PATH.RESOURCES,
      search: getNewQueryString(search, { tab: name }),
    });
  };

  renderTagsTab() {
    if (typeof this?.context?.getValueAsync !== 'function') {
      return;
    }

    this.context.getValueAsync('enableAwsResourcesTags', false).then(value => {
      if (value) {
        this.mounted &&
          this.setState({
            tabList: [
              ...tabList,
              { name: COST_TYPE.TAGS, icon: <AWSCompareIcon /> },
              { name: COST_TYPE.CM, icon: <CMRuleIcon /> },
            ],
          });
      }
    });
  }

  clearFiltration = () => {
    const { isMultipleCurrency = false } = this.props;
    this.setState(
      {
        sorting: {
          type: 'desc',
          range: {
            from: LAST_30_DAYS.format(FILTER_DATE_FORMAT),
            to: YESTERDAY.format(FILTER_DATE_FORMAT),
          },
        },
        searchString: '*',
        startDate: LAST_30_DAYS,
        endDate: YESTERDAY,
        launchStart: undefined,
        launchEnd: undefined,
        cmKey: '',
        include_credit: false,
        tagData: {},
        cit: [],
        currentFilter: isMultipleCurrency
          ? {
              'cloud_type.keyword': ['aws'],
            }
          : {},
        payload: isMultipleCurrency
          ? {
              cloudPlatformState: ['aws'],
              cloudPlatformState_values: [{ id: 'aws', value: 'AWS', cloudPlatform: 'aws' }],
            }
          : {},
        isAWSSelected: true,
      },
      () => {
        if (this.state.selectedTab === COST_TYPE.CM || this.state.selectedTab === COST_TYPE.TAGS) {
          this.loadFilterData();
        }

        this.props.history.push({
          pathname: ROUTES_PATH.RESOURCES,
          search: `tab=${this.state.selectedTab}`,
        });
      },
    );
  };

  handleExcludeCredits = () => {
    const newState = { ...this.state };

    const payload = {
      startDate: moment(newState.startDate).format('YYYY-MM-DD'),
      endDate: moment(newState.endDate).format('YYYY-MM-DD'),
      cloudPlatformState: ['aws'],
      cloudPlatformState_values: [{ id: 'aws', value: 'AWS', cloudPlatform: 'aws' }],
      // toggle credits when using exclude credits checkbox
      include_credit: !this.state.include_credit,
    };
    this.updateFilter(payload);
  };

  updateFilter = payload => {
    let search = removeQueryString(this.props.location.search, 'data');

    const state = {
      payload: {
        ...this.state.payload,
        ...payload,
        startDate: changeDateToStringFormat(payload.startDate),
        endDate: changeDateToStringFormat(payload.endDate),
      },
    };
    const stateString = JSON.stringify(state);
    const newEncodedURI = encodeURIComponent(stateString);
    search = getNewQueryString(search, { tab: this.state.selectedTab });
    search = `${search}&data=${newEncodedURI}`;
    this.props.history.push({
      pathname: ROUTES_PATH.RESOURCES,
      search: search,
      hash: this.props.location.hash,
    });
  };

  closeCreateChargebackModal = () => {
    this.setState({
      showCreateChargeback: false,
    });
  };

  openCreateChargebackModal = () => {
    this.setState({
      showCreateChargeback: true,
    });
  };

  onChargebackCallback = () => {
    this.setState({
      showCreateChargeback: false,
    });
    this.props.history.push({ pathname: ROUTES_PATH.CHARGEBACK_CENTER_V2 });
  };

  updateSorting = (startDate, endDate) => {
    const { sorting } = this.state;
    let tmpSorting = { ...sorting };

    if (tmpSorting.range.from === startDate && tmpSorting.range.to === (endDate ? endDate : startDate)) {
      tmpSorting.type = tmpSorting.type === 'desc' ? 'asc' : 'desc';
    } else {
      tmpSorting = {
        type: 'desc',
        range: {
          from: startDate,
          to: endDate ? endDate : startDate,
        },
      };
    }

    this.setState({ sorting: tmpSorting }, () => {
      this.loadCostData();
      this.loadFilterData();
    });
  };

  getContentLoader = () => {
    const { selectedTab } = this.state;

    if (selectedTab === COST_TYPE.CM) {
      return DetailsCMPageLoader;
    }

    if (selectedTab === COST_TYPE.TAGS) {
      return TagsLoader;
    }

    return CostControlLoader;
  };

  renderCMPage = () => {
    const { searchString, endDate, currentFilter, isAWSSelected = true } = this.state;

    const {
      configCatAzure,
      azure_billing_currency = 'USD',
      isMultipleCurrency = false,
      loading,
      filterLoading,
      summaryLoading,
      defaultFilterLoading,
    } = this.props;
    const endD = cloneDeep(endDate);

    const filters = {
      q: searchString,
      cost_from: LAST_30_DAYS.format(FILTER_DATE_FORMAT),
      cost_to: endD.format(FILTER_DATE_FORMAT),
      filters: currentFilter,
    };

    return (
      <ChangeManagement
        {...this.props}
        filters={filters}
        startDate={LAST_30_DAYS}
        endDate={endDate}
        parentLoading={loading || summaryLoading || filterLoading || defaultFilterLoading}
        history={this.props.history}
        configCatAzure={configCatAzure}
        isAWSSelected={isAWSSelected}
        azure_billing_currency={azure_billing_currency}
        isMultipleCurrency={isMultipleCurrency}
      />
    );
  };

  renderTagsContent() {
    const { selectedTab, searchString, startDate, endDate, currentFilter, tagsAgg, isAWSSelected = true } = this.state;
    const { configCatAzure, azure_billing_currency = 'USD', isMultipleCurrency = false } = this.props;
    const startD = cloneDeep(startDate);
    const endD = cloneDeep(endDate);

    const filters = {
      q: searchString,
      cost_from: startD.format(FILTER_DATE_FORMAT),
      cost_to: endD.format(FILTER_DATE_FORMAT),
      filters: currentFilter,
    };

    return (
      <AWSTags
        filters={filters}
        startDate={startD.format(FILTER_DATE_FORMAT)}
        endDate={endD.format(FILTER_DATE_FORMAT)}
        type={selectedTab}
        tagsAgg={tagsAgg}
        history={this.props.history}
        configCatAzure={configCatAzure}
        isAWSSelected={isAWSSelected}
        azure_billing_currency={azure_billing_currency}
        isMultipleCurrency={isMultipleCurrency}
      />
    );
  }

  renderRightContent() {
    const {
      selectedTab,
      startDate,
      endDate,
      isShowForecast,
      estimatedSpend,
      showKeyword,
      searchString,
      currentFilter,
      tagData,
      cit,
      launchStart,
      launchEnd,
      include_credit,
      isAWSSelected,
    } = this.state;

    const {
      costForAll,
      accountData,
      summaryData,
      summaryCreditsData,
      summaryLoading,
      mrcData,
      configCatAzure,
      selectedFilters,
      filtersCloud,
      isMultipleCurrency = false,
      azure_billing_currency = 'USD',
    } = this.props;
    let filtersCloudData = [];

    if (selectedTab === COST_TYPE.TAGS) {
      return this.renderTagsContent();
    }

    if (selectedTab === COST_TYPE.CM) {
      return this.renderCMPage();
    }

    if (selectedTab === COST_TYPE.CLOUD_ACCOUNTS) {
      filtersCloudData = filtersCloud?.arp_summary_projects || {};
    } else if (selectedTab === COST_TYPE.REGIONS) {
      filtersCloudData = filtersCloud?.arp_summary_regions || {};
    }

    return (
      <>
        <SpendSummary
          loading={summaryLoading}
          data={summaryData || {}}
          onCreateChargeback={this.openCreateChargebackModal}
          isShowForecast={isShowForecast}
          estimatedSpend={estimatedSpend}
          startDate={startDate}
          endDate={endDate}
          configCatAzure={configCatAzure}
          selectedTab={selectedTab}
          handleExcludeCredits={this.handleExcludeCredits}
          includeCredit={include_credit}
          summaryCreditsData={summaryCreditsData}
          isMultipleCurrency={isMultipleCurrency}
          isAWSSelected={isAWSSelected}
          azure_billing_currency={azure_billing_currency}
        />
        <ErrorBoundary>
          <TableChart
            isMultipleCurrency={isMultipleCurrency}
            isAWSSelected={isAWSSelected}
            azure_billing_currency={azure_billing_currency}
            accountData={accountData}
            startDate={startDate}
            endDate={endDate}
            launchStart={launchStart}
            launchEnd={launchEnd}
            data={getTabData(costForAll, selectedTab) || {}}
            type={selectedTab}
            onServerSorting={this.updateSorting}
            showForecastChart={this.showForecastChart}
            isShowForecast={isShowForecast}
            setEstimatesSpend={this.setEstimatesSpend}
            estimatedSpend={estimatedSpend}
            updateFilter={this.updateFilter}
            showKeyword={showKeyword}
            searchString={searchString}
            filters={{ filters: currentFilter }}
            filtersCloud={filtersCloudData}
            selectedFilters={selectedFilters}
            mrcData={mrcData?.arp_mrc_3mo || {}}
            history={this.props.history}
            location={this.props.location}
            tagData={tagData}
            cit={cit}
            configCatAzure={configCatAzure}
          />
        </ErrorBoundary>
      </>
    );
  }

  render() {
    const {
      filters,
      filtersCloud,
      defaultFilters,
      defaultFiltersCloud,
      defaultFilterLoading,
      loading,
      accountData,
      filterLoading,
      summaryLoading,
      userData,
      configCatAzure,
      isMultipleCurrency,
      completeSetup,
    } = this.props;
    const { showCreateChargeback, selectedTab, payload, isShowForecast, tabList, isAWSSelected } = this.state;
    const con = !loading && !summaryLoading && !filterLoading && !defaultFilterLoading;
    const projCon = !userData?.profile?.has_project;
    const noDataCon = projCon && userData?.profile?.is_admin;

    return (
      <>
        <div className="np-content np-arp">
          <div className="np-container-fluid">
            <BillingInconsistencyBanner userData={userData} />
            {noDataCon ? (
              <CloudAccountBanner
                onCompleteSetup={completeSetup}
                sharesave_allowed={userData?.sharesave_allowed}
                has_marketplace_subscription_or_contract={userData?.profile?.has_marketplace_subscription_or_contract}
              />
            ) : null}
            <div className="np-row">
              <div className="npxs-12">
                <div className="np-pageHead">
                  <div className="np-pageTitle">
                    <h1>Cloud Resources Cost</h1>
                    <p>How to always be aware of cost changes and history to stay on top of your bills.</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="np-row">
              <div className="npxs-12">
                <div className="np-wrapper">
                  <div className="np-contentMain full">
                    <div className="np-tabs">
                      <ul className="tabs">
                        {(tabList || []).map(row => (
                          <TabComponent
                            key={row.name}
                            row={row}
                            selectedTab={this.state.selectedTab}
                            onItemClick={this.selectTab}
                          />
                        ))}
                      </ul>
                    </div>

                    {projCon ? (
                      <div className="page">
                        <NoData {...NO_DATA_CONTENT[selectedTab]} />
                      </div>
                    ) : (
                      <div className="page">
                        <div className="np-filters">
                          <ErrorBoundary>
                            <Filters
                              history={this.props.history}
                              filters={payload}
                              dataFilters={filters}
                              dataFiltersCloud={filtersCloud}
                              defaultFilters={defaultFilters}
                              defaultFiltersCloud={defaultFiltersCloud}
                              clearFiltration={this.clearFiltration}
                              updateFilter={this.updateFilter}
                              loading={!con}
                              isShow={configCatAzure ? [...IS_SHOW, 'cloudPlatform'] : IS_SHOW}
                              type={selectedTab}
                              isShowForecast={isShowForecast}
                              filterProps={filterProps}
                              configCatAzure={configCatAzure}
                              hideAzure={false}
                              isCostControl={true}
                              isAWSSelected={isAWSSelected}
                            />
                          </ErrorBoundary>
                        </div>

                        <div className="np-rightContent">
                          <SelectedFilters
                            availableFilters={
                              configCatAzure && !isMultipleCurrency ? [...IS_SHOW, 'cloudPlatform'] : IS_SHOW
                            }
                          />
                          <LoadingWrapper
                            isLoading={loading || filterLoading || defaultFilterLoading}
                            customLoader={this.getContentLoader()}
                            customLoadingClassName={
                              selectedTab === COST_TYPE.TAGS ? 'loading-wrapper-tags' : 'loading-wrapper-cost-control'
                            }
                          >
                            <div className="np-row">{this.renderRightContent()}</div>
                          </LoadingWrapper>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showCreateChargeback && accountData.length > 0 && (
          <CreateChargeback
            projects={userData?.projects?.length ? userData?.projects : []}
            onClose={this.closeCreateChargebackModal}
            chargebackCallback={this.onChargebackCallback}
            selectedChargeback={{}}
            configCatAzure={configCatAzure}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  costForAll: makeSelectCostForAll(),
  accountData: makeAccountFilter(),
  loading: makeSelectDataLoading(),
  filterLoading: makeSelectFilterLoading(),
  filters: makeSelectCostFilters(),
  filtersCloud: makeSelectCostFiltersCloud(),
  defaultFilters: makeSelectCostDefaultFilters(),
  defaultFiltersCloud: makeSelectCostDefaultFiltersCloud(),
  defaultFilterLoading: makeSelectCostDefaultFiltersLoading(),
  summaryLoading: summaryLoadingSelector(),
  summaryData: summaryDataSelector(),
  summaryCreditsData: summaryCreditsDataSelector(),
  summaryFilter: summaryFilterDataSelector(),
  hasProject: makeSelectHasProject(),
  mrcData: makeMRCSelectCostForAll(),
  selectedFilters: makeSelectSetFilters(),
  azure_billing_currency: makeAzureBillingCurrency(),
  isMultipleCurrency: makeIsMultipleCurrency(),
});

const withConnect = connect(mapStateToProps, {
  loadFilterDataRequest,
  loadAllCostDataRequest,
  loadCostSummaryRequest,
  loadMRCCostData,
  loadDefaultFilterDataRequest,
  showToastAction,
});

const withGlobalReducer = injectReducer({
  key: 'global',
  reducer: globalReducer,
});
const withReducer = injectReducer({ key, reducer });
const withSaga = injectSaga({ key, saga });

export default compose(withGlobalReducer, withReducer, withSaga, withConnect)(AWSResourceCost);
