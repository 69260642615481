import { useEffect, useRef } from 'react';

const isProtectedTarget = (targetClass, protectedClasses) => {
  return protectedClasses.some(protectedClass => targetClass.includes(protectedClass));
};

export const useOnClickOutside = (ref, handler, customClassName, protectedClasses = ['select__option']) => {
  const savedHandler = useRef(handler);

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const listener = event => {
      const targetClass = event?.target?.classList?.value || '';
      const hasCustomClass = event?.currentTarget?.body?.className === customClassName;
      const isInsideRef = ref.current.contains(event.target);
      const isProtected = isProtectedTarget(targetClass, protectedClasses);

      if (isInsideRef || hasCustomClass || isProtected) {
        return;
      }

      savedHandler.current(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, customClassName, protectedClasses]);
};
