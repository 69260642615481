import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import forIn from 'lodash/forIn';
import orderBy from 'lodash/orderBy';
import sortBy from 'lodash/sortBy';
import BaseTable from './BaseTable';
import BaseChart from './BaseChart';
import { getResourceName, numberFix } from 'utils/helper';
import {
  TODAY,
  ONE_MORE_MONTH_FROM_TODAY,
  START_OF_MONTH,
  END_OF_MONTH,
  FILTER_DATE_FORMAT,
  YESTERDAY,
} from 'utils/filter';
import { COST_TYPE } from 'utils/constants';

const TYPE_MAP = {
  'AWS Accounts': ['arp_projects', 'arp_projects'],
  'Cloud Accounts': ['arp_projects', 'arp_projects'],
  Regions: ['arp_regions', 'arp_regions'],
  'AWS Managed Services': ['arp_products', 'resources'],
  'Cloud Services': ['arp_products', 'resources'],
  Resources: ['arp_resources', 'results'],
  'Non-resources': ['arp_products', 'resources'],
  Tags: ['arp_tags', 'arp_tags'],
  'Usage Types': ['arp_usagetypes_nested'],
  Operations: ['arp_operations_nested'],
};
const pageSize = 10;

export function selectedIsMonth(startDate, endDate) {
  if (!startDate || !endDate) {
    return false;
  }

  return moment(endDate, FILTER_DATE_FORMAT).diff(moment(startDate, FILTER_DATE_FORMAT), 'months', true) >= 2;
}

export function getIndexOfDate(columns) {
  const date = moment().utc().subtract(1, 'day').format().split('T')[0];

  return (columns || []).findIndex(x => x === date);
}

export function getFilteredDates(startDate = null, endDate = null, defaultRange) {
  if (!moment(startDate, FILTER_DATE_FORMAT).isValid() || !moment(endDate, FILTER_DATE_FORMAT).isValid()) {
    return [];
  }

  const dateArray = [];
  const tmpDate = moment(startDate, FILTER_DATE_FORMAT);
  let diffIn = 'days';

  if (selectedIsMonth(tmpDate, endDate) && !defaultRange) {
    diffIn = 'months';
  }

  while (tmpDate.format(FILTER_DATE_FORMAT) <= endDate.format(FILTER_DATE_FORMAT)) {
    dateArray.push(tmpDate.format(FILTER_DATE_FORMAT));
    tmpDate.add(1, diffIn);
  }

  return dateArray;
}

const getForecastData = (startDate, endDate) => {
  const sDate = startDate.format().split('T')[0];
  const sDateFormat = START_OF_MONTH.format().split('T')[0];
  const eDate = endDate.format().split('T')[0];
  const eDateFormat = YESTERDAY.format().split('T')[0];
  const isMTD = sDate === sDateFormat && eDate === eDateFormat;

  return getFilteredDates(START_OF_MONTH, isMTD ? END_OF_MONTH : ONE_MORE_MONTH_FROM_TODAY);
};

class TableChart extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      total: 1,
      sorting: {},
      dataRows: [],
      forecastRows: [],
      // mrcSorting: 'desc'
    };
  }

  componentDidMount() {
    this.setDefaultProps();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { accountData, type, data: propData, startDate, endDate, mrcData = {} } = this.props;

    if (type !== prevProps.type) {
      this.setState({
        sorting: {},
      });
    }

    if (
      !isEqual(accountData, prevProps.accountData) ||
      !isEqual(propData, prevProps.data) ||
      !isEqual(startDate, prevProps.startDate) ||
      !isEqual(endDate, prevProps.endDate) ||
      !isEqual(mrcData, prevProps.mrcData)
    ) {
      this.setDefaultProps();
    }
  }

  setDefaultProps = () => {
    const { accountData, type, data: propData, startDate, endDate, mrcData, selectedFilters } = this.props;

    if (isEmpty(propData) || isEmpty(accountData)) {
      return null;
    }

    let rawData = get(propData, TYPE_MAP[type][0]);

    if (type === COST_TYPE.USAGE_TYPES) {
      rawData = get(rawData, 'arp_usagetypes');
    } else if (type === COST_TYPE.OPERATIONS) {
      rawData = get(rawData, 'arp_operations');
    }

    if (isEmpty(rawData)) {
      return {
        dataRows: [],
        forecastRows: [],
      };
    }

    const filteredForecastDates = getForecastData(startDate, endDate);
    const filteredDates = getFilteredDates(startDate, endDate);
    const oneMoreMonth = getFilteredDates(TODAY, ONE_MORE_MONTH_FROM_TODAY);
    const currentMonthFilterDate = getFilteredDates(START_OF_MONTH, END_OF_MONTH);

    const temp = {},
      tempArray = [],
      currentMonthCost = {};

    const dataRows = (rawData?.buckets || []).map((row, rowIndex) => {
      const data = {};
      let total = 0;
      let filtered;
      let name;
      let rawCost = {};

      switch (type) {
        case COST_TYPE.USAGE_TYPES:
        case COST_TYPE.OPERATIONS:
          rawCost = row;

          break;
        case COST_TYPE.NON_RESOURCES:
          rawCost = row.non_resources_agg[TYPE_MAP[type][1]];

          break;
        default:
          rawCost = row[TYPE_MAP[type][1]];
      }

      // filter out non-resources that have no data
      if (type === COST_TYPE.NON_RESOURCES && rawCost.daily.daily_data.buckets.length === 0) {
        return null;
      }

      rawCost.daily.daily_data.buckets.forEach(costRow => {
        if (selectedIsMonth(startDate, endDate)) {
          if (costRow.summary) {
            total += costRow.summary.value || 0;

            const keyAsString = costRow.key_as_string.slice(0, 10);

            for (let i = 0, len = filteredDates.length; i <= len; i++) {
              if (moment(keyAsString).isSame(moment(filteredDates[i]), 'month')) {
                if (filteredDates[i] in data) {
                  data[filteredDates[i]] += costRow.summary.value;
                } else {
                  data[filteredDates[i]] = costRow.summary.value;
                }

                break;
              }
            }
          }
        } else if (filteredDates.indexOf(costRow.key_as_string.slice(0, 10)) > -1) {
          if (costRow.summary !== undefined) {
            total += costRow.summary.value || 0;

            const keyAsString = costRow.key_as_string.slice(0, 10);

            if (keyAsString in data) {
              data[keyAsString] += costRow.summary.value;
            } else {
              data[keyAsString] = costRow.summary.value;
            }
          }
        }

        const keyAsString = costRow.key_as_string.slice(0, 10);
        let cost = costRow.summary
          ? costRow.summary.value
          : oneMoreMonth.indexOf(keyAsString) > -1 && costRow.forecast
            ? costRow.forecast.value
            : 0;
        cost = numberFix(cost);

        if (filteredForecastDates.indexOf(keyAsString) > -1) {
          if (cost) {
            temp[keyAsString] = temp[keyAsString] ? temp[keyAsString] + (cost || 0) : cost || 0;
          }
        }

        if (currentMonthFilterDate.indexOf(keyAsString) > -1) {
          if (cost) {
            const keyAsString = costRow.key_as_string.slice(0, 10);
            currentMonthCost[keyAsString] = currentMonthCost[keyAsString]
              ? currentMonthCost[keyAsString] + (cost || 0)
              : cost || 0;
          }
        }
      });

      forIn(data, (value, key) => {
        data[key] = Number(value.toFixed(2)) || 0;
      });

      const ids = {};
      let productName = '';

      if (type === COST_TYPE.AWS_ACCOUNTS && accountData?.length) {
        filtered = (accountData || []).filter(account => account.id === row.key)[0];
        name = filtered ? filtered.value : row.key;
      } else if (type === 'Resources') {
        const hits = row?.arp_resource?.hits?.hits || [];
        name = hits.length ? hits[0]._source : {};
        name = name?.name || name?.cost?.meta?.resource || '';

        if (name?.startsWith('na:')) {
          return null;
        }

        name = getResourceName(name);

        if (hits.length && (hits[0]._source?.search || hits[0]._source?.ct)) {
          ids.projectId = hits.length ? hits[0]._index.split(':')[0].split('_')[1] : 0;
          ids.resourceID = hits.length ? hits[0]._id : 0;
        }

        productName = hits.length ? hits[0]._source.product : '';
      }

      let mrc = 0;

      if (!isEmpty(mrcData) && [COST_TYPE.AWS_ACCOUNTS, COST_TYPE.REGIONS, COST_TYPE.AWS_PRODUCTS].includes(type)) {
        mrc = 0;

        let mrcTypeData;

        if (type === COST_TYPE.AWS_PRODUCTS) {
          mrcTypeData = mrcData.products?.buckets || [];
        } else if (type === COST_TYPE.AWS_ACCOUNTS) {
          mrcTypeData = mrcData.accounts?.buckets || [];
        } else if (type === COST_TYPE.REGIONS) {
          mrcTypeData = mrcData.regions?.buckets || [];
        }

        const rec = (mrcTypeData || []).find(m => m.key === row.key);

        if (rec) {
          mrc = rec?.mrc?.value;
        }
      }

      const cloudPlatform = row.cloudPlatform;
      const rowName = name || row.key;

      const rowRec = {
        id: row.key,
        linkIds: ids || {},
        name: rowName,
        mrc: mrc || 0,
        total: Number(total.toFixed(2)),
        data: data,
        productName: productName || '',
        cloudPlatform,
      };

      if (
        selectedFilters?.usageTypeDataSearch === rowName?.toLowerCase() ||
        selectedFilters?.operationDataSearch === (rowName === 'None' ? rowName : rowName?.toLowerCase())
      ) {
        const cloudColor = `var(--brand-${selectedFilters?.cloudPlatformState[0]}-color)`;
        rowRec.nameCustomStyle = {
          background: cloudColor,
        };
      }

      rowRec.nonResourcesTotal =
        this.props.filtersCloud?.buckets?.[
          rowIndex
        ]?.non_resources?.arp_summary_tag_values_cost?.non_resources_summary?.summary.value;

      return rowRec;
    });

    filteredForecastDates.forEach(x => {
      if (temp[x]) {
        tempArray.push(temp[x].toFixed(2));
      } else {
        tempArray.push(0);
      }
    });

    let count = 0;

    for (let i = 0; i < oneMoreMonth.length; i++) {
      count += temp[oneMoreMonth[i]] ? Number(temp[oneMoreMonth[i]]) : 0;
    }

    this.props.setEstimatesSpend(Number(count.toFixed(2)));

    let filterData = (dataRows || []).filter(row => row !== null);

    if (type !== COST_TYPE.RESOURCES && type !== COST_TYPE.OPERATIONS && type !== COST_TYPE.USAGE_TYPES) {
      filterData = sortBy(filterData, 'total').reverse();
    }

    this.setState({
      total: Math.ceil(filterData.length / pageSize),
      dataRows: filterData,
      forecastRows: tempArray,
      page: 1,
    });
  };

  handleChangePage = data => {
    this.setState({ page: data.selected + 1 });
  };

  updateSorting = key => {
    const { dataRows } = this.state;
    const sorting = { ...this.state.sorting };

    if (sorting.day === key) {
      sorting.type = sorting.type === 'desc' ? 'asc' : 'desc';
    } else {
      sorting.day = key;
      sorting.type = 'asc';
    }

    let data = [];

    if (key === 'total') {
      data = orderBy(dataRows, key, sorting.type);
    } else {
      const isDesc = sorting.type === 'desc' ? 1 : -1;
      data = sortBy(dataRows, row => {
        const value = get(row, `data[${sorting.day}]`, 0);

        return value * isDesc;
      });
    }

    this.setState({ sorting, dataRows: data });
  };

  handleTotal = () => {
    this.setState({
      page: 1,
    });
  };

  mrcSorting = () => {
    let { mrcSorting } = this.state;
    const temp = orderBy(this.state.dataRows, 'mrc', mrcSorting);

    mrcSorting = mrcSorting === 'asc' ? 'desc' : 'asc';

    this.setState({ dataRows: temp, mrcSorting });
  };

  render() {
    const {
      data,
      startDate,
      endDate,
      type,
      onServerSorting,
      isShowForecast,
      filters,
      filtersCloud,
      tagData,
      launchStart,
      launchEnd,
      configCatAzure,
      isMultipleCurrency = false,
      isAWSSelected = true,
      azure_billing_currency = 'USD',
    } = this.props;

    if (!data || Object.keys(data).length === 0) {
      return null;
    }

    const { page, total, forecastRows, dataRows } = this.state;
    const dateArray = getFilteredDates(startDate, endDate);
    const filteredForecastDates = getForecastData(startDate, endDate);

    const pageData = {
      limit: pageSize,
      page: page,
      total: total,
    };
    let text = '';

    if ([COST_TYPE.AWS_ACCOUNTS, COST_TYPE.REGIONS, COST_TYPE.AWS_PRODUCTS].includes(type)) {
      text = ` Daily spend by ${type}`;

      if (selectedIsMonth(startDate, endDate)) {
        text = ` Monthly spend by ${type}`;
      }
    } else {
      text = ` Daily spend of top ${dataRows.length} ${type}`;

      if (selectedIsMonth(startDate, endDate)) {
        text = ` Monthly spend of top ${dataRows.length} ${type}`;
      }
    }

    return (
      <>
        {dateArray.length ? (
          <BaseChart
            location={this.props.location}
            columns={[...dateArray]}
            data={dataRows}
            forecastData={forecastRows}
            pageData={pageData}
            showForecastChart={this.props.showForecastChart}
            isShowForecast={isShowForecast}
            forecastColumn={filteredForecastDates}
            type={type}
            updateFilter={this.props.updateFilter}
            configCatAzure={configCatAzure}
            isMultipleCurrency={isMultipleCurrency}
            isAWSSelected={isAWSSelected}
            azure_billing_currency={azure_billing_currency}
          />
        ) : null}

        <BaseTable
          history={this.props.history}
          title={text}
          columns={dateArray}
          data={dataRows}
          pageData={pageData}
          type={type}
          filters={filters}
          filtersCloud={filtersCloud}
          onServerSorting={onServerSorting}
          onSorting={this.updateSorting}
          handleChangePage={this.handleChangePage}
          handleTotal={this.handleTotal}
          updateFilter={this.props.updateFilter}
          startDate={this.props.startDate}
          endDate={this.props.endDate}
          showKeyword={this.props.showKeyword}
          searchString={this.props.searchString}
          mrcSorting={this.mrcSorting}
          tagData={tagData}
          launchStart={launchStart}
          launchEnd={launchEnd}
          configCatAzure={configCatAzure}
          isMultipleCurrency={isMultipleCurrency}
          isAWSSelected={isAWSSelected}
          azure_billing_currency={azure_billing_currency}
        />
      </>
    );
  }
}

TableChart.propTypes = {
  accountData: PropTypes.arrayOf(PropTypes.shape({})),
  type: PropTypes.string,
  data: PropTypes.shape({}),
  startDate: PropTypes.shape({}),
  endDate: PropTypes.shape({}),
  onServerSorting: PropTypes.func,
};

export default TableChart;
