import { call, put, takeLatest } from 'redux-saga/effects';
import { getData, getFilterData, getRulesData, getHistoryRulesData, getCMData, getCMRulesData } from 'api/rule';
import {
  LOAD_RULE_DETAIL_VIOLATION_DATA,
  LOAD_RULE_VIOLATION_DATA,
  LOAD_CM_RULE_VIOLATION_DATA_REQUEST,
  LOAD_CM_RULE_DETAIL_VIOLATION_DATA_REQUEST,
  LOAD_RULE_DETAIL_FILTER_DATA,
  LOAD_RULE_FILTER_DATA,
} from './constants';
import {
  loadDataSuccess,
  loadDataError,
  loadRuleDetailDataSuccess,
  loadRuleDetailDataError,
  loadCMRuleDetailDataSuccess,
  loadCMRuleDetailDataError,
  loadCMDataSuccess,
  loadCMDataError,
  loadRuleDetailFilterDataSuccess,
  loadRuleDetailFilterDataError,
  loadRuleFilterDataSuccess,
  loadRuleFilterDataError,
} from './action';
import { showToastAction } from 'containers/App/actions';
import { api_condition_error, api_condition_success, globalShowNotification } from 'containers/App/saga';

export function* getRuleData(action) {
  try {
    const res = yield call(getData, action.payload);

    if (api_condition_success(res.status)) {
      yield put(
        loadDataSuccess({
          data: res.data,
          filters: action.payload.payload,
          time: action.payload.time,
        }),
      );
    } else if (api_condition_error(res.status)) {
      yield call(globalShowNotification, res);
      yield put(loadDataError());
    }
  } catch (err) {
    yield put(loadDataError());
    yield put(
      showToastAction({
        message: 'Failed to load rules. Data ingestion may affect the process..',
        type: 'error',
        show: true,
      }),
    );
  }
}

export function* getAllCMRuleData(action) {
  try {
    const type = action.payload?.params?.type || '';
    const res = yield call(getCMData, action.payload);

    if (api_condition_success(res.status)) {
      yield put(
        loadCMDataSuccess({
          data: type === 'cached' ? res.data : res.data.aggregations,
        }),
      );
    } else if (api_condition_error(res.status)) {
      yield call(globalShowNotification, res);
      yield put(loadCMDataError());
    }
  } catch (err) {
    yield put(loadCMDataError());
  }
}

export function* getRuleFilterData(action) {
  try {
    const res = yield call(getFilterData, action.payload);

    if (api_condition_success(res.status)) {
      yield put(
        loadRuleFilterDataSuccess({
          counts: res.data,
        }),
      );
    } else if (api_condition_error(res.status)) {
      yield put(loadRuleFilterDataError());
    }
  } catch (err) {
    yield put(loadRuleFilterDataError());
  }
}

export function* getRuleDetailData(action) {
  try {
    const { rule = {}, range = {}, time = '', customParams = {} } = action.payload;

    const res = yield call(getRulesData, {
      ...rule,
      range: range,
      time: time,
      customParams,
    });

    if (api_condition_success(res.status)) {
      yield put(
        loadRuleDetailDataSuccess({
          data: res.data,
        }),
      );
    } else if (api_condition_error(res.status)) {
      yield call(globalShowNotification, res);
      yield put(loadRuleDetailDataError());
    }
  } catch (err) {
    yield put(loadRuleDetailDataError());
    yield put(
      showToastAction({
        message: 'Failed to load rule data.',
        type: 'error',
        show: true,
      }),
    );
  }
}

export function* getCMRuleDetailData(action) {
  try {
    const { rule = {}, range = {}, time = '', customParams = {} } = action.payload;

    const res = yield call(getCMRulesData, {
      ...rule,
      range: range,
      time: time,
      customParams,
    });

    if (api_condition_success(res.status)) {
      yield put(
        loadCMRuleDetailDataSuccess({
          data: res.data,
        }),
      );
    } else if (api_condition_error(res.status)) {
      yield call(globalShowNotification, res);
      yield put(loadCMRuleDetailDataError());
    }
  } catch (err) {
    yield put(loadCMRuleDetailDataError());
  }
}

export function* getRuleDetailFilterData(action) {
  try {
    const { range = {}, time = '' } = action.payload;

    const res1 = yield call(getFilterData, {
      params: action.payload.rule,
      range: range,
      time: time,
    });
    const res2 = yield call(getHistoryRulesData, action.payload.history);

    if (api_condition_success(res2.status) && api_condition_success(res1.status)) {
      yield put(
        loadRuleDetailFilterDataSuccess({
          counts: {
            currentCounts: res1.data.aggregations,
            historyCounts: res2.data.aggregations,
          },
        }),
      );
    } else {
      yield put(loadRuleDetailFilterDataError());
    }
  } catch (err) {
    yield put(loadRuleDetailFilterDataError());
  }
}

export default function* reportData() {
  yield takeLatest(LOAD_RULE_VIOLATION_DATA, getRuleData);
  yield takeLatest(LOAD_RULE_DETAIL_VIOLATION_DATA, getRuleDetailData);
  yield takeLatest(LOAD_CM_RULE_VIOLATION_DATA_REQUEST, getAllCMRuleData);
  yield takeLatest(LOAD_CM_RULE_DETAIL_VIOLATION_DATA_REQUEST, getCMRuleDetailData);
  yield takeLatest(LOAD_RULE_FILTER_DATA, getRuleFilterData);
  yield takeLatest(LOAD_RULE_DETAIL_FILTER_DATA, getRuleDetailFilterData);
}
