import React, { useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import { compose } from 'redux';
import { connect, useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import { GRAPHQL_API_WITH_HEADER } from 'api/graphql';
import { RULES_DESCRIPTION_TEXT } from 'utils/RuleDetail';
import { FILTER_DATE_FORMAT, YESTERDAY, START_OF_MONTH } from 'utils/filter';
import { getCloudResourceCost } from 'api/cloud-resource-cost';
import { getCloudResourceCostForShowback } from 'api/showback';

import { Anchor } from 'components/Custom';

import globalReducer from 'containers/App/reducer';
import rulesReducer, { loadOverrideDataRequest } from 'containers/nOpsRules/AllRulesDetailPages/reducer';
import { CustomSideModal } from 'components/CustomModal';

import {
  makeUserData,
  makeConfigCatAzure,
  makeSelectClientId,
  makeAzureBillingCurrency,
  makeIsMultipleCurrency,
} from 'containers/App/selectors';
import { showToastAction } from 'containers/App/actions';
import { makeSelectAllPartnerClients, makeSelectPartnerId } from 'containers/Partner/BasePage/selectors';

import { RESOURCES_TABS } from '../constants';
import { ResourceDetail } from './ResourceDetails';
import { CostHistory } from './CostHistory';

import { makeSelectSearchResourceData, makeSelectSearchRLoading } from '../selectors';
import { loadResourceData } from '../actions';
import reducer from '../reducer';
import saga from '../saga';
import resourceReducer, { RESOURCE_DETAIL_SLICE, loadOverrideHistoryDataRequest } from './reducer';
import { getDaysBetweenRange, getMonthsBetweenRange } from '../../CloudResourceCost-API/helper';
import { presets, presets_month } from '../../Filters/constants';

const globalKey = 'global';
const tabs = [RESOURCES_TABS.RESOURCES_DETAILS, RESOURCES_TABS.COST_HISTORY];

export const INTERVAL = [
  {
    value: 1,
    title: '1 week',
    date_start: presets[0].start,
    date_end: presets[0].end,
    columns: getDaysBetweenRange(presets[0].start, presets[0].end),
    granularity: 'day',
  },
  {
    value: 2,
    title: '2 week',
    date_start: presets[1].start,
    date_end: presets[1].end,
    columns: getDaysBetweenRange(presets[1].start, presets[1].end),
    granularity: 'day',
  },
  {
    value: 3,
    title: 'MTD',
    date_start: presets[2].start,
    date_end: presets[2].end,
    columns: getDaysBetweenRange(presets[2].start, presets[2].end),
    granularity: 'day',
  },
  {
    value: 4,
    title: '3 months',
    date_start: presets_month[0].start,
    date_end: presets_month[0].end,
    columns: getMonthsBetweenRange(presets_month[0].start, presets_month[0].end),
    granularity: 'month',
  },
  {
    value: 5,
    title: '6 months',
    date_start: presets_month[1].start,
    date_end: presets_month[1].end,
    columns: getMonthsBetweenRange(presets_month[1].start, presets_month[1].end),
    granularity: 'month',
  },
];
const ResourceDetails = ({ ...props }) => {
  const {
    id,
    resource_type,
    projectId,
    isPartner: { isPartner = false, client_id = 0, partner_scope_id = 0 } = {},
    configCatAzure,
    getViolationData,
    ruleType = '',
    isMultipleCurrency = false,
    azure_billing_currency = 'USD',
    isCurrencyVisible = false,
    isSharesaveScheduler = false,
    recommendation,
    dateRange,
    closable = true,
    isShowback = false,
    showbackId,
    costParams = {},
  } = props;
  const key = id || 0;
  const [activeTab, setActiveTab] = useState(RESOURCES_TABS.RESOURCES_DETAILS);
  const [rawData, setRawData] = useState({});
  const [overrideRecord, setOverrideRecord] = useState([]);
  const [networkInterfaceData, setNetworkInterfaceData] = useState([]);
  const [_cloudPlatform, setCloudPlatform] = useState(null);
  const [cost, setCost] = useState({});
  const [costChartData, setCostChartData] = useState({});
  const [loading, setLoading] = useState(false);
  const [costHistoryLoading, setCostHistoryLoading] = useState(false);
  const [activeInterval, setActiveInterval] = useState(INTERVAL[4]);
  const { overrideData, overridePayload, overrideLoading } = useSelector(state => state['RULE_DETAILS']);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isEmpty(props.resourcesDetailData)) {
      const data = props.resourcesDetailData.find(value => {
        return value._id === key || value._source.item_id === key;
      });

      if (data) {
        getCostHistoryData(data._source?.item_id);
      }
    }
  }, [activeInterval]); //eslint-disable-line

  useEffect(() => {
    if (!overrideData?.length) {
      return;
    }

    const records = overrideData.filter(override => override.resource_id === id);
    setOverrideRecord(records);
  }, [overrideData]); //eslint-disable-line

  useEffect(() => {
    getSearchResourceData();
    // eslint-disable-next-line
  }, [id, projectId]);

  const getSearchResourceData = () => {
    if (!key) {
      return null;
    }

    props.loadResourceData(key, projectId, isPartner ? partner_scope_id : 0, isSharesaveScheduler, resource_type);
  };

  useEffect(() => {
    if (!isEmpty(rawData) && rawData?._source?.type === 'nat') {
      const id = rawData?._source?.item_id.split('/')?.[1] || '';

      if (id.includes('nat-')) {
        getData(id);
      }
    }

    if (!isEmpty(rawData) && (rawData._source?.cloud_type || 'aws')) {
      setCloudPlatform(rawData._source?.cloud_type || 'aws');
    }
  }, [rawData]); //eslint-disable-line

  useEffect(() => {
    if (!isEmpty(props.resourcesDetailData) && !props.loading) {
      const data = props.resourcesDetailData.find(value => {
        return value._id === key || value._source.item_id === key;
      });

      if (data) {
        setRawData({ ...data });
        getCostData(data._source?.item_id);
        getCostHistoryData(data._source?.item_id);
        dispatch(loadOverrideHistoryDataRequest({ id, partner_scope_id }));
        handleOverrideData(data);
      }
    }
    // eslint-disable-next-line
  }, [props.resourcesDetailData, props.loading]);

  const getCostData = async item_id => {
    setLoading(true);

    let response;

    const params = {
      ...costParams,
      resource_id: item_id,
      granularity: 'day',
      date_start: START_OF_MONTH.format(FILTER_DATE_FORMAT),
      date_end: YESTERDAY.format(FILTER_DATE_FORMAT),
    };

    if (!isShowback) {
      response = await getCloudResourceCost({
        params: {
          ...params,
          dimension: 'line_item_resource_id',
        },
      });
    } else {
      response = await getCloudResourceCostForShowback({
        id: showbackId,
        params: {
          ...params,
          dimension: 'line_item_resource_id',
        },
      });
    }

    if (response.status === 200) {
      setCost(response.data?.[item_id] || {});
    }

    setLoading(false);
  };

  const getCostHistoryData = async item_id => {
    setCostHistoryLoading(true);

    let operation, usageType;

    const params = {
      ...costParams,
      resource_id: item_id,
      granularity: activeInterval.granularity,
      date_start: activeInterval.date_start.format(FILTER_DATE_FORMAT),
      date_end: activeInterval.date_end.format(FILTER_DATE_FORMAT),
    };

    if (!isShowback) {
      operation = await getCloudResourceCost({
        params: {
          ...params,
          dimension: 'line_item_operation',
        },
      });
      usageType = await getCloudResourceCost({
        params: { ...params, dimension: 'line_item_usage_type' },
      });
    } else {
      operation = await getCloudResourceCostForShowback({
        id: showbackId,
        params: {
          ...params,
          dimension: 'line_item_operation',
        },
      });
      usageType = await getCloudResourceCostForShowback({
        id: showbackId,
        params: { ...params, dimension: 'line_item_usage_type' },
      });
    }

    if (operation.status === 200 || usageType === 200) {
      setCostChartData({
        operation: operation.status === 200 ? operation.data : {},
        usageType: usageType.status === 200 ? usageType.data : {},
      });
    }

    setCostHistoryLoading(false);
  };

  const handleOverrideData = data => {
    const rule_name = uniq((data?._source?.violations || []).map(violation => violation.violation_type));

    if (!rule_name.length) {
      return;
    }

    const project_ids = [+projectId];

    const payload = {
      project_ids,
      rule_name: rule_name,
    };

    if (isEqual(payload, overridePayload) || overrideLoading) {
      return;
    }

    dispatch(
      loadOverrideDataRequest({
        payload,
        callback: undefined,
        partner_scope_id,
      }),
    );
  };

  const handleActiveTab = t => () => setActiveTab(t);

  const onClose = () => {
    props.onClose();
  };

  const getData = async id => {
    if (!id) {
      return;
    }

    try {
      const response = await GRAPHQL_API_WITH_HEADER({
        data: {
          operationName: 'MyQuery',
          query: `query MyQuery {ec2_nat_gateways(limit: 10 where: {nat_gateway_id: {_eq: "${id}"}}){nat_gateway_addresses}}`,
          variables: null,
        },
        id: rawData?._source?.project?.id,
      });
      setNetworkInterfaceData(response?.data?.data?.ec2_nat_gateways?.[0]?.nat_gateway_addresses || []);
    } catch (error) {
      console.error(error); //eslint-disable-line
    }
  };

  const name = rawData?._source?.name
    ? rawData?._source?.name
    : rawData?._source?.item_id
      ? rawData?._source?.item_id
      : '';
  let projects;

  if (!isPartner) {
    projects = props?.userData?.projects || [];
  } else {
    const rec = props?.partnerClients.find(p => +p.id === +client_id);

    if (!rec || !client_id) {
      projects = [];
    } else {
      projects = rec?.projects || [];
    }
  }

  const resourceDetailsProps = {
    recommendation,
    resource_id: id,
    projectId,
    data: { ...rawData },
    projects,
    isPartner: props?.isPartner || {},
    overrideRecord: overrideRecord,
    getSearchResourceData: getSearchResourceData,
    loading: props.loading || overrideLoading,
    rulesDescription: RULES_DESCRIPTION_TEXT,
    configCatAzure,
    getViolationData,
    ruleType,
    dateRange,
    networkInterfaceData,
    isMultipleCurrency,
    azure_billing_currency,
    _cloudPlatform,
    isCurrencyVisible,
    showToastAction: props.showToastAction,
    cost,
  };

  return (
    <>
      <CustomSideModal
        wrapperClass="cmComparison np-resourceDetail resource-details-expand"
        title={`Resource Details ${name}`}
        onClose={onClose}
        closable={closable}
        loading={loading}
      >
        <div className="np-tabs popTabs">
          <ul className="tabs">
            {tabs.map(t => {
              if (
                (rawData?._source?.type === 'iam' || rawData?._source?.type === 'iam_role') &&
                t === RESOURCES_TABS.COST_HISTORY
              ) {
                return null;
              }

              return (
                <li key={t} className={activeTab === t ? 'active' : ''}>
                  <Anchor onClick={handleActiveTab(t)}>{t}</Anchor>
                </li>
              );
            })}
          </ul>
        </div>

        <div className="body np-search ">
          <div className="np-row">
            <div className="npxs-12">
              <div className="page">
                {activeTab === RESOURCES_TABS.RESOURCES_DETAILS ? (
                  <ResourceDetail {...resourceDetailsProps} />
                ) : rawData?._source?.type !== 'iam' && activeTab === RESOURCES_TABS.COST_HISTORY ? (
                  <CostHistory
                    data={rawData}
                    networkInterfaceData={networkInterfaceData}
                    clientId={props.clientId}
                    costChartData={costChartData}
                    activeInterval={activeInterval}
                    setActiveInterval={setActiveInterval}
                    loading={costHistoryLoading}
                    INTERVAL={INTERVAL}
                  />
                ) : null}
              </div>
            </div>
          </div>

          <div className="np-input-group">
            <button className="np-button light close" onClick={onClose}>
              Close
            </button>
          </div>
        </div>
      </CustomSideModal>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectSearchRLoading(),
  resourcesDetailData: makeSelectSearchResourceData(),
  userData: makeUserData(),
  configCatAzure: makeConfigCatAzure(),
  partnerClients: makeSelectAllPartnerClients(),
  partnerId: makeSelectPartnerId(),
  clientId: makeSelectClientId(),
  azure_billing_currency: makeAzureBillingCurrency(),
  isMultipleCurrency: makeIsMultipleCurrency(),
});

const withConnect = connect(mapStateToProps, {
  loadResourceData,
  showToastAction,
});

const withGlobalReducer = injectReducer({
  key: globalKey,
  reducer: globalReducer,
});
const withReducer = injectReducer({ key: 'SEARCH_PAGE', reducer });
const withSaga = injectSaga({ key: 'SEARCH_PAGE', saga });

const withRuleDetailReducer = injectReducer({
  key: 'RULE_DETAILS',
  reducer: rulesReducer,
});

const withResourceDetailReducer = injectReducer({
  key: RESOURCE_DETAIL_SLICE,
  reducer: resourceReducer,
});

export default compose(
  withRuleDetailReducer,
  withResourceDetailReducer,
  withGlobalReducer,
  withReducer,
  withSaga,
  withConnect,
)(ResourceDetails);
