import {
  LOAD_INITIAL_REQUEST,
  LOAD_FIRST_BUNCH_SUCCESS,
  LOAD_SECOND_BUNCH_SUCCESS,
  LOAD_THIRD_BUNCH_SUCCESS,
  LOAD_INITIAL_SUCCESS,
  LOAD_INITIAL_ERROR,
  LOGIN_REQUEST,
  LOGIN_ERROR,
  LOAD_AUTO_SUGGESTION_DATA,
  LOAD_AUTO_SUGGESTION_DATA_SUCCESS,
  LOAD_AUTO_SUGGESTION_DATA_ERROR,
  LOAD_CLIENT_ACCOUNT_DETAIL_SUCCESS,
  LOAD_CLIENT_ACCOUNT_DETAIL_ERROR,
  CLEAR_SUGGESTION_DATA,
  SHOW_TOAST,
  REMOVE_TOAST,
  SET_OVERRIDE_TIMESTAMP,
  SET_CLIENT_EXPLORE,
  SET_CLIENT_HUBSPOT,
  SET_OVERRIDE_TIMESTAMP_SUCCESS,
  UPDATE_POLICY_ALL_PROJECT_REQUEST,
  UPDATE_POLICY_ALL_PROJECT_ERROR,
  UPDATE_POLICY_PER_PROJECT_REQUEST,
  UPDATE_POLICY_PER_PROJECT_ERROR,
  LOAD_POLICY_DATA_SUCCESS,
  LOAD_POLICY_DATA_ERROR,
  GET_JIRA_CREDENTIALS_SUCCESS,
  GET_JIRA_CREDENTIALS_REQUEST,
  GET_JIRA_CREDENTIALS_ERROR,
  GET_JIRA_ISSUES_REQUEST,
  GET_JIRA_ISSUES_SUCCESS,
  GET_JIRA_ISSUES_ERROR,
  LOAD_CONFIGCAT_REQUEST,
  LOAD_CONFIGCAT_SUCCESS,
  LOAD_JIRA_OAUTH2_DATA_REQUEST,
  LOAD_JIRA_OAUTH2_DATA_SUCCESS,
  LOAD_JIRA_OAUTH2_DATA_ERROR,
  LOAD_NEW_PROFILE_PIC,
  SET_THEME,
  LOAD_CONFIGCAT_FLAGS_REQUEST,
  LOAD_CONFIGCAT_FLAGS_SUCCESS,
  LOAD_CONFIGCAT_FLAGS_ERROR,
} from './constants';

export function loginRequest(payload) {
  return {
    type: LOGIN_REQUEST,
    payload,
  };
}

export function loginError() {
  return {
    type: LOGIN_ERROR,
  };
}

export function loadInitial() {
  return {
    type: LOAD_INITIAL_REQUEST,
  };
}

export function loadFirstBunchSuccess(response) {
  return {
    type: LOAD_FIRST_BUNCH_SUCCESS,
    response,
  };
}

export function loadSecondBunchSuccess(response) {
  return {
    type: LOAD_SECOND_BUNCH_SUCCESS,
    response,
  };
}

export function loadThirdBunchSuccess(response) {
  return {
    type: LOAD_THIRD_BUNCH_SUCCESS,
    response,
  };
}

export function loadInitialSuccess(response) {
  return {
    type: LOAD_INITIAL_SUCCESS,
    response,
  };
}

export function loadInitialError() {
  return {
    type: LOAD_INITIAL_ERROR,
  };
}

export function loadAutoSuggestionData(query) {
  return {
    type: LOAD_AUTO_SUGGESTION_DATA,
    query,
  };
}

export function loadAutoSuggestionDataSuccess(response) {
  return {
    type: LOAD_AUTO_SUGGESTION_DATA_SUCCESS,
    response,
  };
}

export function loadAutoSuggestionDataError() {
  return {
    type: LOAD_AUTO_SUGGESTION_DATA_ERROR,
  };
}

export function loadClientDataSuccess(response) {
  return {
    type: LOAD_CLIENT_ACCOUNT_DETAIL_SUCCESS,
    response,
  };
}

export function loadClientDataError() {
  return {
    type: LOAD_CLIENT_ACCOUNT_DETAIL_ERROR,
  };
}

export function clearSuggestionData() {
  return {
    type: CLEAR_SUGGESTION_DATA,
  };
}

export const showToastAction = payload => ({
  type: SHOW_TOAST,
  payload,
});

export const removeToastAction = pos => ({
  type: REMOVE_TOAST,
  pos,
});

export const setTimeStamp = payload => {
  return {
    type: SET_OVERRIDE_TIMESTAMP,
    payload,
  };
};

export const setClientExplore = payload => {
  return {
    type: SET_CLIENT_EXPLORE,
    payload,
  };
};

export const setClientHubspot = payload => {
  return {
    type: SET_CLIENT_HUBSPOT,
    payload,
  };
};

export const setTimeStampSuccess = response => {
  return {
    type: SET_OVERRIDE_TIMESTAMP_SUCCESS,
    response,
  };
};

export function loadPolicySuccess(response) {
  return {
    type: LOAD_POLICY_DATA_SUCCESS,
    response,
  };
}

export function loadPolicyError() {
  return {
    type: LOAD_POLICY_DATA_ERROR,
  };
}

export function updatePolicyPerProjectRequest(payload) {
  return {
    type: UPDATE_POLICY_PER_PROJECT_REQUEST,
    payload,
  };
}

export function updatePolicyPerProjectError() {
  return {
    type: UPDATE_POLICY_PER_PROJECT_ERROR,
  };
}

export function updatePolicyForAllProjectRequest(payload) {
  return {
    type: UPDATE_POLICY_ALL_PROJECT_REQUEST,
    payload,
  };
}

export function updatePolicyForAllProjectError() {
  return {
    type: UPDATE_POLICY_ALL_PROJECT_ERROR,
  };
}

export function getJiraCredRequest() {
  return {
    type: GET_JIRA_CREDENTIALS_REQUEST,
  };
}

export function getJiraCredSuccess(response) {
  return {
    type: GET_JIRA_CREDENTIALS_SUCCESS,
    response,
  };
}

export function getJiraCredError(error) {
  return {
    type: GET_JIRA_CREDENTIALS_ERROR,
    error,
  };
}

export function getJiraIssuesRequest() {
  return {
    type: GET_JIRA_ISSUES_REQUEST,
  };
}

export function getJiraIssuesSuccess(response) {
  return {
    type: GET_JIRA_ISSUES_SUCCESS,
    response,
  };
}

export function getJiraIssuesError() {
  return {
    type: GET_JIRA_ISSUES_ERROR,
  };
}

export function loadConfigCatValueRequest(flag) {
  return {
    type: LOAD_CONFIGCAT_REQUEST,
    flag,
  };
}

export function loadConfigCatValueSuccess(response) {
  return {
    type: LOAD_CONFIGCAT_SUCCESS,
    response,
  };
}

export function loadJIRAOAuth2Data(payload) {
  return {
    type: LOAD_JIRA_OAUTH2_DATA_REQUEST,
    payload,
  };
}

export function loadJIRAOAuth2Success(response) {
  return {
    type: LOAD_JIRA_OAUTH2_DATA_SUCCESS,
    response,
  };
}

export function loadJIRAOAuth2Error(error) {
  return {
    type: LOAD_JIRA_OAUTH2_DATA_ERROR,
    error,
  };
}

export function loadNewProfilePic(payload) {
  return {
    type: LOAD_NEW_PROFILE_PIC,
    payload,
  };
}

export function setAppTheme(payload) {
  return {
    type: SET_THEME,
    payload,
  };
}

export function loadFeatureFlagsRequest() {
  return {
    type: LOAD_CONFIGCAT_FLAGS_REQUEST,
  };
}

export function loadFeatureFlagsSuccess(payload) {
  return {
    type: LOAD_CONFIGCAT_FLAGS_SUCCESS,
    payload,
  };
}

export function loadFeatureFlagsError(error) {
  return {
    type: LOAD_CONFIGCAT_FLAGS_ERROR,
    error,
  };
}
