export const LOAD_INITIAL_REQUEST = 'GLOBAL/LOAD_INITIAL';
export const LOAD_FIRST_BUNCH_SUCCESS = 'GLOBAL/LOAD_FIRST_BUNCH_SUCCESS';
export const LOAD_SECOND_BUNCH_SUCCESS = 'GLOBAL/LOAD_SECOND_BUNCH_SUCCESS';
export const LOAD_THIRD_BUNCH_SUCCESS = 'GLOBAL/LOAD_THIRD_BUNCH_SUCCESS';
export const LOAD_INITIAL_SUCCESS = 'GLOBAL/LOAD_INITIAL_SUCCESS';
export const LOAD_INITIAL_ERROR = 'GLOBAL/LOAD_INITIAL_ERROR';
export const LOGIN_REQUEST = 'GLOBAL/LOGIN_REQUEST';
export const LOGIN_ERROR = 'GLOBAL/LOGIN_ERROR';

export const LOAD_AUTO_SUGGESTION_DATA = 'LOAD_AUTO_SUGGESTION_DATA';
export const LOAD_AUTO_SUGGESTION_DATA_SUCCESS = 'GLOBAL/LOAD_AUTO_SUGGESTION_DATA_SUCCESS';
export const LOAD_AUTO_SUGGESTION_DATA_ERROR = 'GLOBAL/LOAD_AUTO_SUGGESTION_DATA_ERROR';
export const CLEAR_SUGGESTION_DATA = 'GLOBAL/CLEAR_SUGGESTION_DATA';

export const LOAD_CLIENT_ACCOUNT_DETAIL_SUCCESS = 'GLOBAL/LOAD_CLIENT_ACCOUNT_DETAIL_SUCCESS';
export const LOAD_CLIENT_ACCOUNT_DETAIL_ERROR = 'GLOBAL/LOAD_CLIENT_ACCOUNT_DETAIL_ERROR';

export const SHOW_TOAST = 'GLOBAL/SHOW_TOAST';
export const REMOVE_TOAST = 'GLOBAL/REMOVE_TOAST';

export const SET_OVERRIDE_TIMESTAMP = 'GLOBAL/SET_OVERRIDE_TIMESTAMP';
export const SET_CLIENT_EXPLORE = 'GLOBAL/SET_CLIENT_EXPLORE';
export const SET_CLIENT_HUBSPOT = 'GLOBAL/SET_CLIENT_HUBSPOT';
export const SET_OVERRIDE_TIMESTAMP_SUCCESS = 'GLOBAL/SET_OVERRIDE_TIMESTAMP_SUCCESS';

export const LOAD_POLICY_DATA_SUCCESS = 'LOAD_POLICY_DATA_SUCCESS';
export const LOAD_POLICY_DATA_ERROR = 'LOAD_POLICY_DATA_ERROR';

export const UPDATE_POLICY_PER_PROJECT_REQUEST = 'UPDATE_POLICY_PER_PROJECT_REQUEST';
export const UPDATE_POLICY_PER_PROJECT_ERROR = 'UPDATE_POLICY_PER_PROJECT_ERROR';

export const UPDATE_POLICY_ALL_PROJECT_REQUEST = 'UPDATE_POLICY_ALL_PROJECT_REQUEST';
export const UPDATE_POLICY_ALL_PROJECT_ERROR = 'UPDATE_POLICY_ALL_PROJECT_ERROR';

export const GET_JIRA_CREDENTIALS_REQUEST = 'GET_JIRA_CREDENTIALS_REQUEST';
export const GET_JIRA_CREDENTIALS_SUCCESS = 'GET_JIRA_CREDENTIALS_SUCCESS';
export const GET_JIRA_CREDENTIALS_ERROR = 'GET_JIRA_CREDENTIALS_ERROR';

export const GET_JIRA_ISSUES_REQUEST = 'GET_JIRA_ISSUES_REQUEST';
export const GET_JIRA_ISSUES_SUCCESS = 'GET_JIRA_ISSUES_SUCCESS';
export const GET_JIRA_ISSUES_ERROR = 'GET_JIRA_ISSUES_ERROR';

export const LOAD_CONFIGCAT_REQUEST = 'GLOBAL/LOAD_CONFIGCAT_REQUEST';
export const LOAD_CONFIGCAT_SUCCESS = 'GLOBAL/LOAD_CONFIGCAT_SUCCESS';

export const LOAD_JIRA_OAUTH2_DATA_SUCCESS = 'INTEGRATION/LOAD_JIRA_OAUTH2_DATA_SUCCESS';
export const LOAD_JIRA_OAUTH2_DATA_ERROR = 'LOAD_JIRA_OAUTH2_DATA_ERROR';
export const LOAD_JIRA_OAUTH2_DATA_REQUEST = 'INTEGRATION/LOAD_JIRA_OAUTH2_DATA_REQUEST';
export const LOAD_NEW_PROFILE_PIC = 'LOAD_NEW_PROFILE_PIC';

export const SET_THEME = 'GLOBAL/SET_THEME';

export const LOAD_CONFIGCAT_FLAGS_REQUEST = 'LOAD_CONFIGCAT_FLAGS_REQUEST';
export const LOAD_CONFIGCAT_FLAGS_SUCCESS = 'LOAD_CONFIGCAT_FLAGS_SUCCESS';
export const LOAD_CONFIGCAT_FLAGS_ERROR = 'LOAD_CONFIGCAT_FLAGS_ERROR';
