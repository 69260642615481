import React from 'react';
import ContentLoader from 'react-content-loader';
import { commonColorProps } from './commonProps';

const TABLE_PROPS = {
  speed: 2,
  width: '100%',
  height: 550,
};

export const TableLoader = (
  <>
    <ContentLoader {...TABLE_PROPS} {...commonColorProps}>
      <rect x="0" y="42" rx="3" ry="3" width="100%" height="17"></rect>
      <circle cx="90%" cy="123" r="11"></circle>
      <rect x="10%" y="115" rx="3" ry="3" width="10%" height="15"></rect>
      <rect x="30%" y="114" rx="3" ry="3" width="20%" height="15"></rect>
      <rect x="60%" y="114" rx="3" ry="3" width="21%" height="15"></rect>
      <rect x="0" y="155" rx="3" ry="3" width="100%" height="2"></rect>
      <circle cx="90%" cy="184" r="11"></circle>
      <rect x="10%" y="176" rx="3" ry="3" width="10%" height="15"></rect>
      <rect x="30%" y="175" rx="3" ry="3" width="20%" height="15"></rect>
      <rect x="60%" y="175" rx="3" ry="3" width="21%" height="15"></rect>
      <rect x="0" y="216" rx="3" ry="3" width="100%" height="2"></rect>
      <circle cx="90%" cy="242" r="11"></circle>
      <rect x="10%" y="234" rx="3" ry="3" width="10%" height="15"></rect>
      <rect x="30%" y="233" rx="3" ry="3" width="20%" height="15"></rect>
      <rect x="60%" y="233" rx="3" ry="3" width="21%" height="15"></rect>
      <rect x="0" y="274" rx="3" ry="3" width="100%" height="2"></rect>
      <circle cx="90%" cy="303" r="11"></circle>
      <rect x="10%" y="295" rx="3" ry="3" width="10%" height="15"></rect>
      <rect x="30%" y="294" rx="3" ry="3" width="20%" height="15"></rect>
      <rect x="60%" y="294" rx="3" ry="3" width="21%" height="15"></rect>
      <rect x="0" y="335" rx="3" ry="3" width="100%" height="2"></rect>
      <circle cx="90%" cy="363" r="11"></circle>
      <rect x="10%" y="355" rx="3" ry="3" width="10%" height="15"></rect>
      <rect x="30%" y="354" rx="3" ry="3" width="20%" height="15"></rect>
      <rect x="60%" y="354" rx="3" ry="3" width="21%" height="15"></rect>
      <rect x="0" y="395" rx="3" ry="3" width="100%" height="2"></rect>
      <circle cx="90%" cy="424" r="11"></circle>
      <rect x="10%" y="416" rx="3" ry="3" width="10%" height="15"></rect>
      <rect x="30%" y="415" rx="3" ry="3" width="20%" height="15"></rect>
      <rect x="60%" y="415" rx="3" ry="3" width="21%" height="15"></rect>
      <rect x="0" y="453" rx="3" ry="3" width="100%" height="2"></rect>
      <rect x="0" y="49" rx="3" ry="3" width="2" height="465"></rect>
      <rect x="99.8%" y="49" rx="3" ry="3" width="2" height="465"></rect>
      <circle cx="90%" cy="484" r="11"></circle>
      <rect x="10%" y="476" rx="3" ry="3" width="10%" height="15"></rect>
      <rect x="30%" y="475" rx="3" ry="3" width="20%" height="15"></rect>
      <rect x="60%" y="475" rx="3" ry="3" width="21%" height="15"></rect>
      <rect x="0" y="513" rx="3" ry="3" width="100%" height="2"></rect>
      <rect x="0" y="80" rx="3" ry="3" width="100%" height="17"></rect>
      <rect x="0" y="57" rx="3" ry="3" width="10%" height="33"></rect>
      <rect x="20%" y="54" rx="3" ry="3" width="10%" height="33"></rect>
      <rect x="50%" y="55" rx="3" ry="3" width="10%" height="33"></rect>
      <rect x="81%" y="56" rx="3" ry="3" width="7%" height="33"></rect>
      <rect x="92%" y="54" rx="3" ry="3" width="10%" height="33"></rect>
    </ContentLoader>
  </>
);

export const SimpleTableLoader = (
  <ContentLoader
    speed={2}
    width={1000}
    height={150}
    viewBox="0 0 1000 150"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...commonColorProps}
  >
    <rect x="3" y="10" rx="5" ry="5" width="150" height="20" />
    <rect x="3" y="45" rx="5" ry="5" width="150" height="20" />
    <rect x="3" y="80" rx="5" ry="5" width="150" height="20" />
    <rect x="3" y="115" rx="5" ry="5" width="150" height="20" />
    <rect x="180" y="10" rx="5" ry="5" width="600" height="20" />
    <rect x="180" y="45" rx="5" ry="5" width="600" height="20" />
    <rect x="180" y="80" rx="5" ry="5" width="600" height="20" />
    <rect x="180" y="115" rx="5" ry="5" width="600" height="20" />
  </ContentLoader>
);

const Loader_PROPS = {
  speed: 2,
  width: '100%',
  height: 45,
};

export const CardLoader = (
  <ContentLoader {...Loader_PROPS} {...commonColorProps}>
    <rect x="0" y="8" rx="4" ry="4" width="100%" height="100%" />
  </ContentLoader>
);

const RowLoader_PROPS = {
  speed: 2,
  width: '100%',
  height: 100,
};

export const RowLoader = (
  <ContentLoader {...RowLoader_PROPS} {...commonColorProps}>
    <rect x="0" y="0" rx="4" ry="4" width="100%" height="100%" />
  </ContentLoader>
);

const SmallCellLoader_PROPS = {
  speed: 2,
  width: '50%',
  height: 50,
};

export const SmallCellLoader = (
  <ContentLoader {...SmallCellLoader_PROPS} {...commonColorProps}>
    <rect x="0" y="0" rx="4" ry="4" width="100%" height="100%" />
  </ContentLoader>
);

const SmallRowLoader_PROPS = {
  speed: 2,
  width: '100%',
  height: 50,
};

export const SmallRowLoader = (
  <ContentLoader {...SmallRowLoader_PROPS} {...commonColorProps}>
    <rect x="0" y="0" rx="4" ry="4" width="100%" height="100%" />
  </ContentLoader>
);

const ExtraSmallRowLoader_PROPS = {
  speed: 2,
  width: '100%',
  height: 30,
};

export const ExtraSmallRowLoader = (
  <ContentLoader {...ExtraSmallRowLoader_PROPS} {...commonColorProps}>
    <rect x="0" y="0" rx="4" ry="4" width="100%" height="100%" />
  </ContentLoader>
);
