import React, { Component } from 'react';
import { Anchor } from 'components/Custom';
import { DownCaretIcon } from 'assets/images/common';
import classNames from 'classnames';

export const DropdownItem = ({
  icon,
  name,
  val,
  selectedVal,
  extra,
  onClick,
  toggle,
  isDisabled,
  iconPosition = 'left',
}) => {
  const onItemClick = () => {
    if (isDisabled) {
      return;
    }

    onClick(val, name, extra);
    toggle();
  };

  return (
    <Anchor
      id={val}
      className={classNames('dropdown-item', {
        active: val === selectedVal,
        disabled: isDisabled,
      })}
      onClick={onItemClick}
    >
      {icon && iconPosition === 'left' ? icon : null}
      {name}
      {icon && iconPosition === 'right' ? icon : null}
    </Anchor>
  );
};

class SimpleDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listOpen: false,
      headerTitle: this.props.title,
      icon: this.props.icon,
    };
    this.close = this.close.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { listOpen } = this.state;

    if (this.props.title !== prevProps.title) {
      this.setState({ headerTitle: this.props.title });
    }

    setTimeout(() => {
      if (listOpen) {
        window.addEventListener('click', this.close);
      } else {
        window.removeEventListener('click', this.close);
      }
    }, 0);
  }

  close(timeOut) {
    this.setState({
      listOpen: false,
    });
  }

  toggleList = () => {
    this.setState(prevState => ({
      listOpen: !prevState.listOpen,
    }));
  };

  render() {
    const {
      list,
      className,
      onItemClick,
      color = 'blue',
      btnClassName = '',
      disabled = false,
      selectedItem = { name: '', value: '' },
      styles = {},
    } = this.props;
    const { listOpen, headerTitle, icon } = this.state;

    return (
      <div className={className} style={styles}>
        <div className="np-dropdownClick">
          <button
            id={headerTitle}
            className={classNames(`np-button ${color} ${btnClassName} w-max`, {
              disabled: disabled,
            })}
            onClick={this.toggleList}
            disabled={disabled}
          >
            {icon}
            {headerTitle}
            <DownCaretIcon />
          </button>
          {listOpen && (
            <div className="np-dropdownContent sort right open">
              {(list || []).map((item, index) => {
                const { icon, name, value, isDisabled, iconPosition, ...rest } = item;
                const key = `dropdown-item-${index + 1}`;

                return (
                  <DropdownItem
                    key={key}
                    id={key}
                    isDisabled={isDisabled}
                    icon={icon}
                    name={name}
                    val={value}
                    selectedVal={selectedItem?.value}
                    extra={rest}
                    onClick={onItemClick}
                    iconPosition={iconPosition}
                    toggle={this.toggleList}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default SimpleDropdown;
