import axiosObj from './config';
import { getCookieByName } from 'utils/getCookie';
import { logMyErrors } from 'utils/request';
import { configClient } from 'context/feature-flags-context';
import { payload } from 'components/CreateChargeback/constants';

export function loadLastDeploymentData(context) {
  return axiosObj({
    method: 'GET',
    url: context,
  });
}

export function loadStatus(id) {
  let url = '/c/admin/status/';

  if (id) {
    url = url + `?partner_scope_id=${id}`;
  }

  return axiosObj({
    method: 'GET',
    url,
  });
}

export function loadBillingInfo(useCache) {
  if (useCache) {
    return axiosObj({
      method: 'GET',
      url: '/payment/payment-method/?use_cache=false',
    });
  } else {
    return axiosObj({
      method: 'GET',
      url: '/payment/payment-method/',
    });
  }
}

export function loadProjects(clientId) {
  return axiosObj({
    method: 'GET',
    url: '/c/projects/',
    params: {
      client_id: clientId,
    },
  });
}

export function loadAllProjects() {
  return axiosObj({
    method: 'GET',
    url: '/c/admin/projectaws/',
  });
}

export function loadProjectById(id) {
  return axiosObj({
    method: 'GET',
    url: `/c/admin/projectaws/${id}/`,
  });
}

export function loadAllProjectsList() {
  return axiosObj({
    method: 'GET',
    url: '/c/admin/projectaws_listing/',
  });
}

export function loadOrganizationProjects(params) {
  return axiosObj({
    method: 'GET',
    url: '/c/admin/projectaws/organization_accounts/',
    params,
  });
}

export function loadAzureProjects() {
  return axiosObj({
    method: 'GET',
    url: '/c/admin/azure-projects/',
  });
}

export function loadProfile() {
  return axiosObj({
    method: 'GET',
    url: '/c/current/user/',
  });
}

export function checkEmail(email) {
  return axiosObj({
    method: 'GET',
    url: `/rest-auth/check_email/${email}/`,
  });
}

export function loadTopVpc(payload) {
  return axiosObj({
    method: 'GET',
    url: '/c/ac/vpcs',
    params: payload,
  });
}

export function loadTopTag(payload) {
  return axiosObj({
    method: 'GET',
    url: '/c/ac/tags',
    params: payload,
  });
}

export function login(payload) {
  return axiosObj({
    method: 'POST',
    url: '/rest-auth/login/',
    data: payload,
  });
}

export function loadAutoSuggestion(val) {
  return axiosObj({
    method: 'GET',
    url: '/c/ac/auto-complete/',
    params: {
      query: val,
    },
  });
}

export function selectAccount(form_data) {
  return axiosObj({
    method: 'POST',
    url: '/c/select/',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-CSRFToken': getCookieByName('csrftoken'),
    },
    transformRequest: function (obj) {
      const str = [];

      for (const p in obj) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }

      return str.join('&');
    },
    data: form_data,
  });
}

export function removeFiltersFromURL() {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  let pathname = url.pathname;

  if (pathname === '/v3/settings/manageAccount/') {
    pathname = '/v3/settings';
  } else if (pathname.includes('v3/cost/eks/')) {
    pathname = '/v3/cost/eks/';
  } else if (pathname.includes('/v3/cost/map-migrated/')) {
    pathname = '/v3/cost/map-migrated/';
  }

  params.delete('data'); // Delete 'data' parameter.

  return `${url.origin}${pathname}${params.toString() && `?${params.toString()}`}`;
}

export function switchAccount(clientId) {
  selectAccount({
    client_id: clientId,
  })
    .then(() => {
      const updatedUrl = removeFiltersFromURL();
      window.location.replace(updatedUrl);
    })
    .catch(err => {
      logMyErrors('Switch Account: ', err);
    });
}

export function selectPartnerAccount(form_data) {
  return axiosObj({
    method: 'POST',
    url: '/c/partner_select/',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-CSRFToken': getCookieByName('csrftoken'),
    },
    transformRequest: function (obj) {
      const str = [];

      for (const p in obj) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }

      return str.join('&');
    },
    data: form_data,
  });
}

export function switchPartner(partner_id) {
  selectAccount({
    partner_id,
  })
    .then(() => {
      const updatedUrl = removeFiltersFromURL();
      window.location.replace(updatedUrl);
    })
    .catch(err => {
      logMyErrors('Switch Account: ', err);
    });
}

export function getAllPartnerList(params) {
  return axiosObj({
    method: 'GET',
    url: '/app_partner/partner/',
    params,
  });
}

export function getAllClientList(params) {
  return axiosObj({
    method: 'GET',
    url: '/c/admin/all_clients/',
    params,
  });
}

export function getClientDetail(id) {
  return axiosObj({
    method: 'GET',
    url: `/c/admin/clients/${id}/`,
  });
}

export function updateClientDetail({ id, data }) {
  return axiosObj({
    method: 'PATCH',
    url: `/c/admin/clients/${id}/`,
    data,
  });
}

export const getVPCNames = (param = {}) => {
  const agg = ['vpc_names'];

  const parameters = {
    ...param,
    aggregations: agg.join(':'),
    scope: 'client',
  };

  return axiosObj({
    method: 'GET',
    url: '/c/ac/query',
    params: { parameters },
  });
};

export const getTagFilterData = () => {
  const parameters = {
    q: '*',
    service: 'all_services',
    scope: 'client',
    filters: {},
    counters: true,
    aggregations: 'arp_resource_tag_key_list',
  };

  return axiosObj({
    method: 'GET',
    url: '/c/ac/query',
    params: {
      parameters: { ...parameters },
    },
  });
};

export const getCloudInformationTagsData = () => {
  const parameters = {
    q: '*',
    scope: 'client',
    aggregations: 'arp_resource_tag_value_list',
    show_active: null,
    filters: {
      must: [
        {
          'nested:tags_kv:tags_kv.key': ['aws:cloudformation:stack-name'],
        },
      ],
      _multiple_conditions: [true],
    },
  };

  return axiosObj({
    method: 'GET',
    url: '/c/ac/query',
    params: {
      parameters: { ...parameters },
    },
  });
};

export const getAllAutoScalingGroups = () => {
  const parameters = {
    q: '*',
    aggregations: 'hits_without_billing',
    range: {},
    filters: {
      must: [
        {
          is_as: [true],
        },
      ],
      _multiple_conditions: [true],
    },
  };

  return axiosObj({
    method: 'GET',
    url: '/c/ac/query',
    params: {
      parameters: { ...parameters },
    },
  });
};
// Policy

export function getNewPolicies() {
  return axiosObj({
    method: 'GET',
    url: '/c/policy_version/',
  });
}

export function updatePolicyForProject(id) {
  return axiosObj({
    method: 'POST',
    url: '/c/policy_version/',
    data: {
      project_id: id,
      accepted: 'true',
    },
  });
}

export function updatePolicyForAllProject() {
  return axiosObj({
    method: 'POST',
    url: '/c/policy_version/',
    data: {
      project_id: 'all',
      accepted: 'true',
    },
  });
}

export const getAllCountersData = payload => {
  const agg =
    payload?.configCatAzure && !payload?.hideAzure
      ? [
          'violations_counts_per_project_cloud_type',
          'violations_counts_per_region_cloud_type',
          'violations_counts_per_vpc_cloud_type',
          'arp_resource_tag_key_list_per_billing_type',
          'vpc_names_cloud_type',
          'cf_stacks',
          'autoscaling_group_names_cloud_type',
        ]
      : [
          'violations_counts_per_project',
          'violations_counts_per_region',
          'violations_counts_per_vpc',
          'arp_resource_tag_key_list',
          'vpc_names',
          'cf_stacks',
          'autoscaling_group_names',
        ];

  const parameters = {
    aggregations: agg.join(':'),
    // cloud_type: payload?.configCatAzure ? 'all' : 'aws',
  };

  if (payload) {
    parameters.scope = payload?.isPartner ? 'partner' : 'client';
  }

  if (payload?.configCatAzure && !payload?.hideAzure) {
    parameters.cloud_type = 'all';
  }

  return axiosObj({
    method: 'GET',
    url: '/c/ac/query',
    params: {
      parameters,
    },
  });
};

export const getChargebackFilters = configCatAzure => {
  const agg = configCatAzure
    ? ['arp_summary_billing_type', 'arp_resource_tag_key_list_per_billing_type']
    : ['arp_summary', 'arp_resource_tag_key_list'];

  const parameters = {
    q: '*',
    scope: 'client',
    aggregations: agg.join(':'),
    show_active: null,
    filters: {},
    cloud_type: configCatAzure ? 'all' : 'aws',
  };

  if (configCatAzure) {
    parameters.cloud_type = 'all';
  }

  return axiosObj({
    method: 'GET',
    url: '/c/ac/query',
    params: {
      parameters,
    },
  });
};

// for getting the aws regions
export const getEventBridgeFilters = () => {
  const agg = ['arp_summary'];

  const parameters = {
    q: '*',
    scope: 'client',
    aggregations: agg.join(':'),
    show_active: null,
    filters: {},
    cloud_type: 'aws',
  };

  return axiosObj({
    method: 'GET',
    url: '/c/ac/query',
    params: {
      parameters,
    },
  });
};

export function getTagResourceValueData(payloadValues) {
  const params = payload(payloadValues.key, payloadValues.configCatAzure, payloadValues.cloudPlatform);

  return axiosObj({
    method: 'GET',
    url: '/c/ac/query',
    params,
  });
}

export function getUserSegment() {
  return axiosObj({
    method: 'GET',
    url: '/c/user_segment/',
  });
}

export function getConfigCatValue(flag, user = {}) {
  return configClient.getValueAsync(flag, false, user);
}

export function getUserPackages() {
  return axiosObj({
    method: 'GET',
    url: '/payment/package/',
  });
}

export function setUserPackage(data) {
  return axiosObj({
    method: 'POST',
    url: '/payment/package/activate_trial/',
    data,
  });
}

export function setActiveTrial(data) {
  return axiosObj({
    method: 'POST',
    url: '/payment/package/activate_trial/',
    data,
  });
}

export function setRequestUpgrade(data) {
  return axiosObj({
    method: 'POST',
    url: '/payment/package/request_upgrade/',
    data,
  });
}
