import { CLOUD_RESOURCE_COST_KEYS } from 'containers/CloudResourceCost-API/constants';
import { getTabName } from 'containers/CloudResourceCost-API/helper';
import {
  getDimensionDataForCostAllocationTag,
  getDimensionDataForLabels,
  getDimensionDatForTags,
} from 'api/cloud-resource-cost';
import { currencyFormat } from 'utils/number';
import React from 'react';
import { isStringNull } from 'utils/helper';

export const getLabel = (type, value = false) => {
  if (type === CLOUD_RESOURCE_COST_KEYS.TAGS) {
    return 'untagged';
  }

  return `No ${getTabName(type)}${!value ? '' : ' value'}`;
};

export const getKey = type => {
  switch (type) {
    case CLOUD_RESOURCE_COST_KEYS.LABELS:
      return 'label';
    case CLOUD_RESOURCE_COST_KEYS.COST_ALLOCATION_TAGS:
      return 'cost_allocation_tag';
    default:
      return 'tag';
  }
};

export const getAction = type => {
  switch (type) {
    case CLOUD_RESOURCE_COST_KEYS.LABELS:
      return getDimensionDataForLabels;
    case CLOUD_RESOURCE_COST_KEYS.COST_ALLOCATION_TAGS:
      return getDimensionDataForCostAllocationTag;
    default:
      return getDimensionDatForTags;
  }
};

export const lockedLabel = (label, cost, dimension, fetchAll, isFilterLocked) => {
  const formattedCost = !fetchAll ? `(${currencyFormat(cost)})` : null;

  if (isStringNull(label)) {
    return `${getLabel(dimension)} ${formattedCost}`;
  }

  if (!isFilterLocked) {
    return `${label} ${formattedCost}`;
  }

  return (
    <>
      {label.slice(0, Math.ceil(label.length / 2))}
      <span className={isFilterLocked ? 'locked' : ''}>
        {label.slice(Math.ceil(label.length / 2))} {formattedCost}
      </span>
    </>
  );
};
