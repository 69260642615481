import React, { useState, useEffect, useContext } from 'react';
import { CloudAccountBannerIcon } from 'assets/images';
import FeatureFlagsContext from '../../context/feature-flags-context';

const BillingInconsistencyBanner = ({ userData }) => {
  const context = useContext(FeatureFlagsContext);
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const domain = (userData?.profile?.email || '').split('@').pop();
    context
      .getValueAsync('enableAnnouncementBanner', false, { email: userData?.profile?.email || '', custom: { domain } })
      .then(value => {
        setShowBanner(value);
      });
  }, [userData?.profile]); //eslint-disable-line

  if (!showBanner) {
    return null;
  }

  return (
    <section className="np-cloud-account-banner">
      <div className="content">
        <CloudAccountBannerIcon />
        <div className="content-info">
          <h4 className="m0 font-20 font-bold">Amazon Billing Data Inconsistency Event</h4>
          <p className="color-grey m0 mT5">
            AWS has been reporting billing data inconsistencies for the month of August since <b>Monday, August 14th</b>
            . They expect to propagate the fix within 24 hours, which should be available in our portal by mid-day
            Friday. We are closely monitoring for updates and will remove this banner as soon as the event is fully
            remediated. Check the{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://health.aws.amazon.com/health/status">
              AWS Health Dashboard Event History
            </a>{' '}
            for the most up-to-date information and status.
          </p>
        </div>
      </div>
    </section>
  );
};

export default BillingInconsistencyBanner;
